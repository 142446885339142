import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ProjectContainer from '../containers/ProjectContainer';
import { ProjectTable } from '../components/project/ProjectTable';
import { ProjectModal } from '../components/project/ProjectModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal, Button } from '../components/ui';
import { Project } from '../api/project/types';
import { Tag } from '../api/tag/types';
import { Person } from '../api/person/types';
import { Task } from '../api/task/types';
import { Team } from '../api/team/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { FilterConfig } from '../components/ui/data/Filter';
import { ProjectTimelineView } from '../components/project/ProjectTimelineView';
import { ProjectHeatmapView } from '../components/project/ProjectHeatmapView';
import { ProjectBoardView } from '../components/project/ProjectBoardView';
import { TaskModal } from '../components/task/TaskModal';
import { useQueryClient } from 'react-query';
import TaskContainer from '../containers/TaskContainer';

const ProjectListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<string | null>(null);
  const [projectToEdit, setProjectToEdit] = useState<Project | null>(null);
  const [viewMode, setViewMode] = useState<'table' | 'timeline' | 'heatmap' | 'board'>('table');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [projects, setProjects] = useState<Project[]>([]);
  const [sortBy, setSortBy] = useState<string>('priority');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [initialFilter, setInitialFilter] = useState<Record<string, any>>({});
  const [showCompleted, setShowCompleted] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
  const [showTaskModal, setShowTaskModal] = useState(false);

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (slug && projects.length > 0) {
      const project = projects.find((project) => project.slug === slug);
      if (project) {
        setProjectToEdit(project);
        setShowModal(true);
      }
    }
  }, [slug, projects]);

  useEffect(() => {
    if (!showModal) {  // Only update viewMode if modal is closed
      const path = location.pathname;
      if (path.includes('/projects/board')) {
        setViewMode('board');
      } else if (path.includes('/projects/heatmap')) {
        setViewMode('heatmap');
      } else if (path.includes('/projects/timeline')) {
        setViewMode('timeline');
      } else {
        setViewMode('table');
      }
    }
  }, [location.pathname, showModal]);  // Add showModal to dependencies 

  useEffect(() => {
    const path = location.pathname;
    let newFilter = {};
    if (path.includes("/projects/active")) {
      newFilter = { state: ["DEVELOPMENT_IN_PROGRESS", "TESTING", "ROLLING_OUT"] };
      setShowCompleted(false); // Step 3: Update showCompleted based on path
    } else if (path.includes("/projects/completed")) {
      newFilter = { state: "DONE" };
      setShowCompleted(true); // Step 3: Update showCompleted based on path
    } else if (path.includes("/projects/all")) {
      newFilter = {}; // Clear filters for "All"
      setShowCompleted(false); // Step 3: Update showCompleted based on path
    } else {
      setShowCompleted(false); // Default case
    }

    setFilters(newFilter);
    setInitialFilter(newFilter);
  }, [location.pathname]);
  

  const handleUpdateTitle = (
    slug: string,
    newTitle: string,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { title: newTitle });
    setSnackbarMessage('Project title updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateState = (
    slug: string,
    newState: Project['state'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { state: newState });
    setSnackbarMessage('Project state updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateEffort = (
    slug: string,
    newEffort: Project['effort'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { effort: newEffort });
    setSnackbarMessage('Project effort updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePriority = (
    slug: string,
    newPriority: Project['priority'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { priority: newPriority });
    setSnackbarMessage('Project priority updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePulse = (
    slug: string,
    newPulse: Project['pulse'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { pulse: newPulse });
    setSnackbarMessage('Project pulse updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTags = (
    slug: string,
    newTags: Tag[],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { tags: newTags });
    setSnackbarMessage('Project tags updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePersons = (
    slug: string,
    newPersons: Person[],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { persons: newPersons });
    setSnackbarMessage('Project persons updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateStartDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { start_date: newDate });
    setSnackbarMessage('Project start date updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTargetDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { target_date: newDate });
    setSnackbarMessage('Project target date updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateOwner = (
    slug: string,
    newOwner: Person | null,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { owner: newOwner });
    setSnackbarMessage('Project owner updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTeam = (
    slug: string,
    newTeam: Team | null,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { team: newTeam });
    setSnackbarMessage('Project team updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleDelete = (slug: string) => {
    setProjectToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (projectToDelete) {
      onDelete(projectToDelete);
      setShowDeleteDialog(false);
      setProjectToDelete(null);
      setSnackbarMessage('Project deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    setProjectToEdit(null);
    setShowModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/projects/new?${searchParams.toString()}`);
  };
  

  const handleShowCompletedToggle = () => {
    setShowCompleted(!showCompleted);
  };

  const handleEditProject = (project: Project) => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
    setProjectToEdit(project);
    setShowModal(true);
  };
  

  const toggleViewMode = (mode: 'table' | 'timeline' | 'heatmap' | 'board') => {
    setViewMode(mode);
    navigate(`/projects/${mode === 'table' ? '' : mode}`);
  };

  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => toggleViewMode('table')}
        variant={viewMode === 'table' ? 'primary' : 'secondary'}
        iconSize="md"
        iconColor='white'
        title="Table View"
      />
      <Button
        icon="ChartGantt"
        onClick={() => toggleViewMode('timeline')}
        variant={viewMode === 'timeline' ? 'primary' : 'secondary'}
        iconSize="md"
        iconColor='white'
        title="Timeline View"
      />
      <Button
        icon="Grid"
        onClick={() => toggleViewMode('heatmap')}
        variant={viewMode === 'heatmap' ? 'primary' : 'secondary'}
        iconSize="md"
        iconColor='white'
        title="Heatmap View"
      />
      <Button
        icon="Layout"
        onClick={() => toggleViewMode('board')}
        variant={viewMode === 'board' ? 'primary' : 'secondary'}
        iconSize="md"
        iconColor='white'
        title="Board View"
      />
    </>
  );
  

  const filterConfig: FilterConfig = useMemo(() => {
    const allTags = Array.from(new Set(projects.flatMap(project => project.tags.map(tag => tag.name))));
    const allOwners = Array.from(new Set(projects.map(project => project.owner?.name).filter((name): name is string => name !== undefined)));

    return {
      state: {
        label: 'State',
        value: 'state',
        type: 'select',
        options: [
          { label: 'Candidate', value: 'CANDIDATE' },
          { label: 'Backlog', value: 'BACKLOG' },
          { label: 'Definition and Analysis', value: 'DEFINITION_AND_ANALYSIS' },
          { label: 'Development in Progress', value: 'DEVELOPMENT_IN_PROGRESS' },
          { label: 'Testing', value: 'TESTING' },
          { label: 'Rolling Out', value: 'ROLLING_OUT' },
          { label: 'Done', value: 'DONE' }
        ],
      },
      effort: {
        label: 'Effort',
        value: 'effort',
        type: 'select',
        options: [
          { label: 'XS', value: 'XS' },
          { label: 'S', value: 'S' },
          { label: 'M', value: 'M' },
          { label: 'L', value: 'L' },
          { label: 'XL', value: 'XL' }
        ],
      },
      priority: {
        label: 'Priority',
        value: 'priority',
        type: 'select',
        options: [
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' }
        ],
      },
      pulse: {
        label: 'Pulse',
        value: 'pulse',
        type: 'select',
        options: [
          { label: 'Green', value: 'GREEN' },
          { label: 'Yellow', value: 'YELLOW' },
          { label: 'Orange', value: 'ORANGE' },
          { label: 'Red', value: 'RED' }
        ],
      },
      tags: {
        label: 'Tags',
        value: 'tags',
        type: 'multi-select',
        options: allTags.map(tag => ({ label: tag, value: tag })),
      },
      owner: {
        label: 'Owner',
        value: 'owner',
        type: 'select',
        options: allOwners.map(owner => ({ label: owner, value: owner })),
      },
    };
  }, [projects]);

  const handleFilterChange = (newFilters: Record<string, any>) => {
    setFilters(newFilters);
  };

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'State', value: 'state' },
    { label: 'Priority', value: 'priority' },
    { label: 'Start Date', value: 'start_date' },
    { label: 'Target Date', value: 'target_date' },
    { label: 'Created Date', value: 'created_at' },
  ];

  const compareValues = (a: any, b: any) => (a < b ? -1 : a > b ? 1 : 0);

  const compareDates = (dateA: string | null | undefined, dateB: string | null | undefined): number => {
    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  };

  const filterAndSortProjects = (projects: Project[]): Project[] => {
    let filteredProjects = projects.filter(project =>
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (showCompleted || project.state !== 'DONE') &&
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true; // Skip empty filters
  
        switch (key) {
          case 'state':
            return Array.isArray(value) ? value.includes(project.state) : project.state === value;
          case 'effort':
            return project.effort === value;
          case 'priority':
            return project.priority.toString() === value;
          case 'pulse':
            return project.pulse === value;
          case 'tags':
            if (Array.isArray(value) && value.length > 0) {
              return value.some(tag => project.tags.some(projectTag => projectTag.name === tag));
            }
            return true;
          case 'owner':
            return project.owner?.name === value;
          default:
            return true;
        }
      })
    );
  
    // Sorting logic remains the same
    return filteredProjects.sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case 'title':
          result = a.title.localeCompare(b.title);
          break;
        case 'state':
          result = a.state.localeCompare(b.state);
          break;
        case 'priority':
          result = a.priority - b.priority;
          break;
        case 'created_at':
          result = compareDates(a.created_at, b.created_at);
          break;
        case 'start_date':
          result = compareDates(a.start_date, b.start_date);
          break;
        case 'target_date':
          result = compareDates(a.target_date, b.target_date);
          break;
        default:
          break;
      }
      return sortDirection === 'asc' ? result : -result;
    });
  };
  

  return (
    <div>
      <h1 className="m-4">
        {viewMode === 'heatmap'
          ? 'Projects - Heatmap'
          : viewMode === 'timeline'
          ? 'Projects - Timeline'
          : viewMode === 'board'
          ? 'Projects - Board'
          : 'Projects'}
      </h1>
      <SeparatorHorizontal />
      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search Projects..."
        addButtonLabel="Add New Project"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        defaultSortValue="title"
        filterConfig={filterConfig}
        initialFilter={initialFilter}
        onFilterChange={handleFilterChange}
        onShowComplete={handleShowCompletedToggle}
        showCompleted={showCompleted}
        additionalButtons={additionalButtons}
      />

      <ProjectContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading projects...</p>
            </div>
          );
                    if (error) return <div>{error}</div>;

          if (projects.length === 0 && data.length > 0) {
            setProjects(data);
          }

          const filteredAndSortedProjects = filterAndSortProjects(data);

          const handleSaveProject = (
            projectData: Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>,
            slug?: string
          ) => {
            if (slug) {
              onEdit(slug, projectData);
              setSnackbarMessage('Project updated successfully');
            } else {
              onCreate(projectData);
              setSnackbarMessage('Project created successfully');
            }
            setSnackbarType('success');
            setShowSnackbar(true);
          };

          return (
            <>
              {showTaskModal && taskToEdit && (
                <TaskContainer
                  projectId={taskToEdit.project_id}
                  render={({ data: tasksFromContainer, loading, error, onCreate, onEdit, onDelete }) => {
                    const fullTask = tasksFromContainer.find(t => t.id === taskToEdit.id) || taskToEdit;
                  
                    return (
                      <TaskModal
                        key={fullTask.id} // Add key to force remount when task data changes
                        isOpen={showTaskModal}
                        onClose={() => {
                          setShowTaskModal(false);
                          setTaskToEdit(null);
                        }}
                        initialData={fullTask}
                        onSuccess={(message) => {
                          setSnackbarMessage(message);
                          setSnackbarType('success');
                          setShowSnackbar(true);
                          setShowTaskModal(false);
                          queryClient.invalidateQueries(['tasks', { projectId: taskToEdit.project_id }]);
                          queryClient.invalidateQueries(['projects']);
                        }}
                        onError={(message) => {
                          setSnackbarMessage(message);
                          setSnackbarType('error');
                          setShowSnackbar(true);
                        }}
                        projectId={taskToEdit.project_id}
                      />
                    );
                  }}
                />
              )}

              {showModal && (
                <ProjectModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    navigate(
                      viewMode === 'timeline'
                        ? '/projects/timeline'
                        : viewMode === 'heatmap'
                        ? '/projects/heatmap'
                        : viewMode === 'board'
                        ? '/projects/board'
                        : '/projects/'
                    );
                  }}
                  onSave={projectToEdit ? (data) => handleSaveProject(data, projectToEdit.slug) : handleSaveProject}
                  initialData={
                    projectToEdit
                      ? {
                          ...projectToEdit,
                          topics: projectToEdit.topics || [],
                          dependencies: projectToEdit.dependencies || [],
                          progress: projectToEdit.progress || 0,
                        }
                      : undefined
                  }
                  onEdit={onEdit}
                  onDelete={(slug) => {
                    onDelete(slug); // Invoke delete in the project container
                    setSnackbarMessage('Project deleted successfully');
                    setSnackbarType('error');
                    setShowSnackbar(true);
                  }}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this project?</p>
              </Modal>

              {viewMode === 'timeline' ? (
                <ProjectTimelineView
                  data={filteredAndSortedProjects}
                  onOpenProject={(project) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setProjectToEdit(project);
                    setShowModal(true);
                    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
                  }}
                  onOpenTask={(task) => {
                    // If you also want to preserve mode when opening a task modal, do the same:
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setTaskToEdit(task);
                    setShowTaskModal(true);
                    // If you navigate somewhere for the task, append the mode as well.
                  }}
                />
              ) : viewMode === 'heatmap' ? (
                <ProjectHeatmapView
                  projects={filteredAndSortedProjects}
                  onOpenProject={(project) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setProjectToEdit(project);
                    setShowModal(true);
                    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={(slug, updatedProject) => onEdit(slug, updatedProject)}
                />
              ) : viewMode === 'board' ? (
                <ProjectBoardView
                  onOpenProject={(project) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setProjectToEdit(project);
                    setShowModal(true);
                    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={(id, updatedProject) => onEdit(id, updatedProject)}
                />
              ) : (
                <ProjectTable
                  data={filteredAndSortedProjects}
                  key={filteredAndSortedProjects.map(p => p.slug).join()}
                  onEdit={handleEditProject}
                  onDelete={(slug) => handleDelete(slug)}
                  onUpdateState={(slug, newState) => handleUpdateState(slug, newState, onEdit)}
                  onUpdateEffort={(slug, newEffort) => handleUpdateEffort(slug, newEffort, onEdit)}
                  onUpdatePriority={(slug, newPriority) => handleUpdatePriority(slug, newPriority, onEdit)}
                  onUpdatePulse={(slug, newPulse) => handleUpdatePulse(slug, newPulse, onEdit)}
                  onUpdateTags={(slug, newTags) => handleUpdateTags(slug, newTags, onEdit)}
                  onUpdatePersons={(slug, newPersons) => handleUpdatePersons(slug, newPersons, onEdit)}
                  onUpdateStartDate={(slug, newDate) => handleUpdateStartDate(slug, newDate, onEdit)}
                  onUpdateTargetDate={(slug, newDate) => handleUpdateTargetDate(slug, newDate, onEdit)}
                  onUpdateOwner={(slug, newOwner) => handleUpdateOwner(slug, newOwner, onEdit)}
                  onUpdateTitle={(slug, newTitle) => handleUpdateTitle(slug, newTitle, onEdit)}
                  onUpdateTeam={(slug, newTeam) => handleUpdateTeam(slug, newTeam, onEdit)}
                />
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default ProjectListPage;