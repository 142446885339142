import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import api from '../services/authService';
import { getPersonId, getCompanyId, setTokens, clearTokens } from '../utils/auth';

interface ProfileResponse {
    person: {
        id: string;
        name: string;
        email: string;
        position: string | null;
    };
    company: {
        id: string;
        name: string;
    };
}

interface UserContextProps {
    personId: string | null;
    companyId: string | null;
    setSession: (personId: string, companyId: string) => void;
    clearSession: () => void;
    isAuthenticated: boolean;
    isLoading: boolean;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [personId, setPersonId] = useState<string | null>(getPersonId());
    const [companyId, setCompanyId] = useState<string | null>(getCompanyId());
    const [authenticated, setAuthenticated] = useState<boolean>(Boolean(personId && companyId));
    const [isLoading, setIsLoading] = useState(true);

    const setSession = (personId: string, companyId: string) => {
        setTokens(personId, companyId);
        setPersonId(personId);
        setCompanyId(companyId);
        setAuthenticated(true);
    };

    const clearSession = () => {
        clearTokens();
        setPersonId(null);
        setCompanyId(null);
        setAuthenticated(false);
    };

    useEffect(() => {
        // Only attempt to fetch the profile if both personId and companyId are available
        if (personId && companyId) {
            api.get<ProfileResponse>('profile/')
                .then(({ data }) => {
                    if (data.person?.id && data.company?.id) {
                        setSession(data.person.id, data.company.id);
                    } else {
                        clearSession();
                    }
                })
                .catch(() => clearSession())
                .finally(() => setIsLoading(false));
        } else {
            // If not authenticated (personId or companyId is missing), just skip the profile request
            setIsLoading(false);
        }
    }, [personId, companyId]);  // Dependency array ensures effect runs when personId or companyId change
    

    const value = {
        personId,
        companyId,
        setSession,
        clearSession,
        isAuthenticated: authenticated,
        isLoading
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};