import React from 'react';
import { useTasks, useCreateTask, useEditTask, useDeleteTask } from '../api/task/queries';
import { Task } from '../api/task/types';
import { useQueryClient } from 'react-query';
import { ALERTS_QUERY_KEY } from '../api/alert/queries';

interface TaskContainerProps {
  topicId?: string;
  projectId?: string;
  meetingOccurrenceId?: string;
  meetingTypeId?: string;
  personId?: string;
  startDate?: string;
  endDate?: string;
  render: (props: {
    data: Task[];
    loading: boolean;
    error: string | null;
    onCreate: (newTask: Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void;
    onEdit: (slug: string, updatedTask: Partial<Task>) => void;
    onDelete: (slug: string) => void;
  }) => JSX.Element;
}

const TaskContainer: React.FC<TaskContainerProps> = ({
  topicId,
  projectId,
  meetingOccurrenceId,
  meetingTypeId,
  personId,
  startDate,
  endDate,
  render,
}) => {
  const params = {
    topicId,
    projectId,
    meetingOccurrenceId: meetingTypeId ? undefined : meetingOccurrenceId,
    meetingTypeId,
    personId,
    startDate,
    endDate
  };
  const queryClient = useQueryClient();
  const queryKey = ['tasks', params];

  const { data: tasks = [], isLoading, error } = useTasks(params);
  const createTaskMutation = useCreateTask(params);
  const editTaskMutation = useEditTask(params);
  const deleteTaskMutation = useDeleteTask(params);

  const invalidateProject = (projectId?: string) => {
    if (projectId) {
      queryClient.invalidateQueries(['projects']);
    }
  };

  const handleCreate = (newTask: Omit<Task, 'id' | 'created_at' | 'updated_at' | 'slug'>) => {
    createTaskMutation.mutate(newTask, {
      onSuccess: (createdTask) => {
        queryClient.invalidateQueries(queryKey); // Refresh tasks
        invalidateProject(createdTask.project_id); // Invalidate the associated project
      },
    });
  };

  const handleEdit = (slug: string, updatedTask: Partial<Task>) => {
    const previousTasks = queryClient.getQueryData<Task[]>(queryKey) || [];
    const taskIndex = previousTasks.findIndex((t) => t.slug === slug);

    if (taskIndex === -1) return;

    const optimisticTasks = [...previousTasks];
    optimisticTasks[taskIndex] = { ...optimisticTasks[taskIndex], ...updatedTask };

    queryClient.setQueryData(queryKey, optimisticTasks);

    editTaskMutation.mutate(
      { slug, updatedTask },
      {
        onError: () => {
          queryClient.setQueryData(queryKey, previousTasks); // Rollback on error
        },
        onSuccess: (editedTask) => {
          queryClient.invalidateQueries(queryKey); // Refresh tasks
          invalidateProject(editedTask.project_id); // Invalidate the associated project
        },
      }
    );
  };

  const handleDelete = (slug: string) => {
    const previousTasks = queryClient.getQueryData<Task[]>(queryKey) || [];
    const taskToDelete = previousTasks.find((t) => t.slug === slug);
  
    if (!taskToDelete) return; // Exit if the task is not found
  
    // Optimistically remove the task
    queryClient.setQueryData(queryKey, previousTasks.filter((t) => t.slug !== slug));
  
    deleteTaskMutation.mutate(slug, {
      onError: () => {
        queryClient.setQueryData(queryKey, previousTasks); // Rollback on error
      },
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey); // Refresh tasks
        invalidateProject(taskToDelete.project_id); // Use the project_id from the task before deletion
      },
    });
  };
  

  const sortedTasks = [...tasks].sort((a, b) => a.title.localeCompare(b.title));

  return render({
    data: sortedTasks,
    loading: isLoading,
    error: error ? (error as Error).message : null,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default TaskContainer;
