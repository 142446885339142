import { Strategy, CreateStrategyRequest, UpdateStrategyRequest, CreateCommentRequest, CreateReactionRequest } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export interface FetchStrategiesParams {
  collaboratorId?: string;
  teamId?: string;
}

// Fetch all Strategies with optional filtering
export const fetchStrategies = async (
  params: FetchStrategiesParams,
  navigate: (path: string) => void
): Promise<Strategy[]> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const queryParams = [];
  
  if (params.teamId) {
    queryParams.push(`team_id=${params.teamId}`);
  } else if (params.collaboratorId) {
    queryParams.push(`collaborator_id=${params.collaboratorId}`);
  }

  let url = `${API_URL}strategies/`;
  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  const response = await fetchWithAuth(url, {}, navigate);
  
  if (!response.ok) throw new Error('Failed to fetch Strategies');

  const strategies = await response.json();
  
  return strategies.map((strategy: any) => ({
    ...strategy,
    team: strategy.team_detail || null,
  }));
};

// Fetch a single Strategy by slug
export const fetchStrategy = async (
  slug: string,
  navigate: (path: string) => void
): Promise<Strategy> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}strategies/${slug}/`, {}, navigate);
  
  if (!response.ok) throw new Error(`Failed to fetch Strategy with slug ${slug}`);

  const strategy = await response.json();
  
  return { ...strategy, team: strategy.team_detail || null };
};

// Create a new Strategy
export const createStrategy = async (
  strategyData: CreateStrategyRequest,
  navigate: (path: string) => void
): Promise<Strategy> => {
  const response = await fetchWithAuth(`${API_URL}strategies/`, {
    method: 'POST',
    body: JSON.stringify({
      ...strategyData,
      team: strategyData.team,
      tag_ids: strategyData.tags,
      owner_id: strategyData.owner_id,
    }),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Strategy');
  return response.json();
};

// Edit an existing Strategy
export const editStrategy = async (
  slug: string,
  updatedStrategy: UpdateStrategyRequest,
  navigate: (path: string) => void
): Promise<Strategy> => {
  if (!slug) throw new Error('Strategy slug is required to edit.');

  const response = await fetchWithAuth(`${API_URL}strategies/${slug}/`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...updatedStrategy,
      team: updatedStrategy.team,
      tag_ids: updatedStrategy.tags,
      owner_id: updatedStrategy.owner_id,
    }),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Strategy with slug ${slug}`);
  return response.json();
};

export const deleteStrategy = async (
  slug: string,
  navigate: (path: string) => void
): Promise<void> => {
  if (!slug) throw new Error('Strategy slug is required to delete.');

  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}strategies/${slug}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Strategy with slug ${slug}`);
};


// Create a new Comment
export const createComment = async (
  commentData: CreateCommentRequest,
  navigate: (path: string) => void
) => {
  const response = await fetchWithAuth(`${API_URL}comments/`, {
    method: 'POST',
    body: JSON.stringify(commentData),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create comment');
  return response.json();
};

// Delete a Comment
export const deleteComment = async (
  commentId: string,
  navigate: (path: string) => void
): Promise<void> => {
  const response = await fetchWithAuth(`${API_URL}comments/${commentId}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error('Failed to delete comment');
};

// Create/Toggle a Reaction
export const toggleReaction = async (
  reactionData: CreateReactionRequest,
  navigate: (path: string) => void
) => {
  const response = await fetchWithAuth(`${API_URL}reactions/`, {
    method: 'POST',
    body: JSON.stringify(reactionData),
  }, navigate);

  // If response is 400 with "Reaction removed", it means the reaction was toggled off
  if (response.status === 400) {
    const error = await response.json();
    if (error.detail === "Reaction removed") {
      return null;
    }
  }

  if (!response.ok) throw new Error('Failed to toggle reaction');
  return response.json();
};

// Utility function to check if strategy is editable
export const isStrategyEditable = (strategy: Strategy): boolean => {
  return strategy.state !== 'APPROVED';
};

// Utility function to check if comments are allowed
export const areCommentsAllowed = (strategy: Strategy): boolean => {
  return strategy.state !== 'APPROVED';
};