import React, { useState } from 'react';
import { Project } from '../../api/project/types';
import { Icon, Snackbar, SidePanelItem } from '../ui';
import ProjectContainer from '../../containers/ProjectContainer';
import { ProjectModal } from '../project/ProjectModal';
import { useQueryClient } from 'react-query';
import { entityStyles, projectStateOptions } from '../../utils/entitiesOptions';

interface ProjectPanelProps {
  isOpen: boolean;
  onClose?: () => void;
  teamId?: string;
  personId?: string;
  onProjectClick?: (project: Project) => void;
  title?: string;
}

const ProjectPanel: React.FC<ProjectPanelProps> = ({
  isOpen,
  onClose,
  teamId,
  personId,
  onProjectClick,
  title = '',
}) => {
  const [showModal, setShowModal] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState<Project | null>(null);
  const queryClient = useQueryClient();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');

  const handleProjectClick = (project: Project) => {
    setProjectToEdit(project);
    setShowModal(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        <ProjectContainer
          teamId={teamId}
          personId={personId}
          render={({ data: projectsFromContainer, loading, error, onCreate, onEdit }) => {
            const sortedProjects = [...projectsFromContainer].sort((a, b) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            return (
              <>
                {loading && (
                  <div className="flex items-center justify-center py-4">
                    <Icon name="Loader2" size="sm" className="animate-spin" />
                  </div>
                )}
                {error && <div className="p-4 text-red-500">Error: {error}</div>}
                {!loading && !error && sortedProjects.length === 0 && (
                  <div className="p-4 text-gray-500 text-sm">No projects associated</div>
                )}

                {!loading && !error && sortedProjects.length > 0 && (
                  <div className="space-y-3">
                    {sortedProjects.map((project) => {
                      const stateInfo = entityStyles.PROJECT.getStateInfo(project.state);
                      
                      console.log(project);
                      return (
                        <SidePanelItem
                          key={project.id}
                          title={project.title}
                          icon={entityStyles.PROJECT.icon}
                          prefix={entityStyles.PROJECT.prefix}
                          metadata={{
                            owner: project.owner?.name,
                            state: projectStateOptions.find(option => option.value === project.state)?.label,
                            start_date: project.start_date || undefined,
                            due_date: project.target_date || undefined,
                          }}
                          onClick={() => handleProjectClick(project)}
                          borderColor="border-l-green-500"
                        >
                          <div className="flex items-center gap-2 text-xs text-gray-400 mt-1">
                            {project.target_date && (
                              <div className="flex items-center gap-1">
                                <Icon name="Calendar" size="sm" />
                                <span>Target: {project.target_date}</span>
                              </div>
                            )}
                            <div className="flex items-center gap-1">
                              <Icon name="Activity" size="sm" />
                              <span>Pulse: {project.pulse}</span>
                            </div>
                          </div>
                        </SidePanelItem>
                      );
                    })}
                  </div>
                )}

                {showModal && (
                  <ProjectModal
                    isOpen={showModal}
                    initialData={projectToEdit || undefined}
                    onClose={() => setShowModal(false)}
                    onSave={(projectData) => {
                      setSnackbarMessage('Project saved successfully');
                      setSnackbarType('success');
                      setShowSnackbar(true);
                      setShowModal(false);
                      queryClient.invalidateQueries(['projects', { teamId, personId }]);
                    }}
                    onEdit={onEdit}
                  />
                )}
              </>
            );
          }}
        />

        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectPanel;