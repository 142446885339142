import React, { useState, useEffect, useRef } from 'react';
import { Button, SeparatorHorizontal, InputTitle, MarkDownEditor, Icon, ToggleSwitch, Tooltip } from '../../ui';
import VisibilityToggle from '../utilities/VisibilityToggle';
import Lifecycle from '../data/Lifecycle';
import { useLocation, useNavigate } from 'react-router-dom';

interface ModalContentProps {
  modalTitle?: string;
  title?: string;
  description?: string;
  selects?: Array<{ component: React.FC<any>; props: any }>;
  formFields?: Array<{ component: React.FC<any>; props: any }>;
  onCancel?: () => void;
  onCreate?: () => void;
  onDelete?: () => void;
  onTitleChange?: (value: string) => void;
  onTitleBlur?: () => void;
  onDescriptionChange?: (value: string) => void;
  onDescriptionBlur?: () => void;
  isEdit?: boolean;
  actionButtonLabel?: string;
  showDescription?: boolean;
  showTitle?: boolean;
  showActivityFeed?: boolean;
  activityFeed?: React.ReactNode;
  children?: React.ReactNode;
  showSidePanel?: boolean;
  sidePanelContent?: React.ReactNode;
  isSidePanelInitiallyCollapsed?: boolean;
  parentObjectType?: 'topic' | 'project';
  parentObjectId?: string;
  descriptionPlaceholder?: string;
  titleInputPlaceholder?: string;
  showCreateMoreToggle?: boolean;
  onCreateMoreChange?: (value: boolean) => void;
  resetForm?: () => void;
  showFooterButtons?: boolean;
  initialVisibility?: 'public' | 'private';
  onVisibilityToggle?: (isPublic: boolean) => void;
  isVisibilityEditable?: boolean;
  lifecycleStates?: string[];
  currentState?: string;
  showLifecycle?: boolean;
  descriptionSize?: "small" | "medium" | "large";
  showFavorite?: boolean;
  isFavorited?: boolean;
  onFavoriteToggle?: () => void;
  viewMode?: 'modal' | 'fullscreen' | 'sidePeek'; 
  onViewModeChange?: (mode: 'modal' | 'fullscreen' | 'sidePeek') => void;
  isNestedModal?: boolean;
  showWarning?: boolean;
  warningTooltip?: string;
}

export const ModalContent: React.FC<ModalContentProps> = ({
  modalTitle = "",
  title = "",
  description = "",
  descriptionSize = "medium",
  selects = [],
  formFields = [],
  onCancel,
  onCreate,
  onDelete,
  onTitleChange,
  onTitleBlur,
  onDescriptionChange,
  onDescriptionBlur,
  isEdit = false,
  actionButtonLabel = "Submit",
  showDescription = true,
  showTitle = true,
  showActivityFeed = false,
  activityFeed,
  children,
  showSidePanel = false,
  sidePanelContent,
  isSidePanelInitiallyCollapsed = true,
  parentObjectType,
  parentObjectId,
  descriptionPlaceholder = "Add content...",
  titleInputPlaceholder = "Add title...",
  showCreateMoreToggle = false,
  onCreateMoreChange,
  resetForm,
  showFooterButtons = true,
  initialVisibility = 'private',
  onVisibilityToggle,
  isVisibilityEditable = false,
  lifecycleStates,
  currentState,
  showLifecycle = false,
  showFavorite = false,
  isFavorited = false,
  onFavoriteToggle,
  viewMode = 'sidePeek',
  onViewModeChange,
  isNestedModal = false,
  showWarning = false,
  warningTooltip,
}) => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [createMore, setCreateMore] = useState(false);
  const [localTitle, setLocalTitle] = useState(title);
  const [localDescription, setLocalDescription] = useState(description);
  const location = useLocation();
  const navigate = useNavigate();
  const [isViewModeMenuOpen, setIsViewModeMenuOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const getViewModeFromUrl = (): 'modal' | 'fullscreen' | 'sidePeek' => {
    const searchParams = new URLSearchParams(location.search);
    const mode = searchParams.get('mode');
    switch(mode) {
      case 'f': return 'fullscreen';
      case 's': return 'sidePeek';
      default: return 'modal';
    }
  };

  const [modalViewMode, setModalViewMode] = useState<'modal' | 'fullscreen' | 'sidePeek'>(getViewModeFromUrl());
  const prevModalViewModeRef = useRef(modalViewMode);

  const descriptionHeightClass = descriptionSize === "small"
    ? "min-h-30"
    : descriptionSize === "medium"
      ? "min-h-60"
      : "min-h-80";

  useEffect(() => {
    if (isEdit || (!localTitle && !localDescription)) {
      setLocalTitle(title);
      setLocalDescription(description);
    }
  }, [title, description, isEdit]);

  const handleCreateMoreChange = (checked: boolean) => {
    setCreateMore(checked);
    if (onCreateMoreChange) {
      onCreateMoreChange(checked);
    }
  };

  useEffect(() => {
    setIsSidePanelOpen(!isSidePanelInitiallyCollapsed);
  }, [showSidePanel, isSidePanelInitiallyCollapsed]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onCancel) {
        onCancel();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEdit, onCancel]);

  const isFullscreenState = modalViewMode === 'fullscreen';
  const isSidePeekMode = modalViewMode === 'sidePeek';

  const selectViewMode = (mode: 'modal' | 'fullscreen' | 'sidePeek') => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('mode', mode === 'fullscreen' ? 'f' : mode === 'sidePeek' ? 's' : 'c');
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

    // If switching from fullscreen to another mode, close the side panel
    if (prevModalViewModeRef.current === 'fullscreen' && mode !== 'fullscreen') {
      setIsSidePanelOpen(false);
    }

    setModalViewMode(mode);
    prevModalViewModeRef.current = mode;
    
    if (onViewModeChange) {
      onViewModeChange(mode);
    }
    setIsViewModeMenuOpen(false);
  };

  const toggleSidePanel = () => {
    // If we are opening the side panel and not in fullscreen, switch to fullscreen mode.
    if (!isSidePanelOpen && modalViewMode !== 'fullscreen') {
      selectViewMode('fullscreen');
      setIsSidePanelOpen(true);
    } else {
      // Otherwise, just toggle the side panel open/closed
      setIsSidePanelOpen(!isSidePanelOpen);
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    if (onDelete) {
      onDelete();
    }
    setIsDeleteModalOpen(false);
  };

  const handleSubmit = () => {
    if (onCreate) {
      onCreate();
    }
    if (createMore && resetForm) {
      resetForm();
      setLocalTitle("");
      setLocalDescription("");
    }
  };

  const toggleViewModeMenu = () => {
    setIsViewModeMenuOpen((prev) => !prev);
  };

  return (
    <div
  onClick={(e) => {
    // If it's a modal view (either regular or nested), close on outside click
    if (modalViewMode === 'modal' || isNestedModal) {
      onCancel?.();
    }
  }}
  className={`
    ${isNestedModal ? 'fixed' : isFullscreenState ? 'absolute' : 'fixed'}
    inset-0
    flex justify-center items-center
    ${isFullscreenState && !isNestedModal ? 'mt-3 lg:mt-3' : ''} // (should be 12 if we have tabcontaienr. Adjust top margin dynamically 
    ${isNestedModal ? 'z-[100]' : 'z-50'}
  `}
>
  {/* Modal backdrop */}
  {(modalViewMode === 'modal' || isNestedModal) && (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
  )}
  {modalViewMode === 'fullscreen' && !isNestedModal && (
    <div className="absolute inset-0 bg-gray-800 z-40" />
  )}

  <div
    ref={modalRef}
    onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
    className={`
      bg-white rounded-lg shadow-3xl z-50 flex flex-col overflow-hidden
      ${
        isFullscreenState && !isNestedModal
          ? 'absolute top-0 right-3 bottom-3 left-3' // Fullscreen with 3px margin on sides
          : isSidePeekMode
            ? 'absolute top-12 bottom-3 right-3 w-[650px] border-l border-gray-300' // Side peek mode
            : 'w-[60vw] max-w-[800px] min-w-[650px] max-h-[80vh]' // Regular modal size
      }
    `}
  >


        {/* Modal Header */}
        <div className="flex justify-between items-center border-b border-gray-200 p-4 relative leading-[2.25rem]">
          <div className="flex items-center space-x-4 w-full">
            <h2 className="text-xl font-semibold">{modalTitle}</h2>
            
            {showFavorite && onFavoriteToggle && (
              <button 
                onClick={onFavoriteToggle} 
                className="text-gray-500 hover:text-gray-800"
                title={isFavorited ? "Remove from favorites" : "Add to favorites"}
              >
                <Icon 
                  name={isFavorited ? "Star" : "StarOff"} 
                  size="md"
                  className={isFavorited ? "text-yellow-400" : ""}
                />
              </button>
            )}

            {onVisibilityToggle && (
              <VisibilityToggle
                initialVisibility={initialVisibility}
                onToggle={onVisibilityToggle}
                disabled={!isVisibilityEditable}
              />
            )}

            {showLifecycle && lifecycleStates && currentState && (
              <div className="flex justify-center flex-1 px-2 sm:px-4">
                <div className="max-w-screen-md w-full">
                  <Lifecycle lifecycleStates={lifecycleStates} currentState={currentState} size="small" showActiveLabel={false} />
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center space-x-2 relative">

            
            {showWarning && (
              <Tooltip 
                content={warningTooltip || ''} 
                position="left" 
                variant="default"
              >
                <div className="relative px-2">
                  <Icon 
                    name="AlertTriangle" 
                    size="md" 
                    className="text-yellow-500 hover:text-yellow-600"
                  />
                </div>
              </Tooltip>
            )}

            {showSidePanel && (
              <button onClick={toggleSidePanel} className="text-gray-500 hover:text-gray-800">
                <Icon name={isSidePanelOpen ? "PanelRightClose" : "PanelRightOpen"} size="md" color="primary" />
              </button>
            )}

            {/* View Mode Menu Button */}
            <div className="relative">
              <button onClick={toggleViewModeMenu} className="text-gray-500 hover:text-gray-800" title="Change View Mode">
                <Icon name="Layout" size="md" />
              </button>
              {isViewModeMenuOpen && (
                <div className="absolute right-0 top-full mt-2 bg-white border border-gray-200 rounded shadow-lg z-50 w-32">
                  <button
                    className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    onClick={() => selectViewMode('modal')}
                  >
                    <Icon name="Square" size="sm" className="mr-2 text-gray-600" />
                    Modal
                  </button>
                  <button
                    className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    onClick={() => selectViewMode('fullscreen')}
                  >
                    <Icon name="Maximize2" size="sm" className="mr-2 text-gray-600" />
                    Fullscreen
                  </button>
                  <button
                    className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    onClick={() => selectViewMode('sidePeek')}
                  >
                    <Icon name="Columns" size="sm" className="mr-2 text-gray-600" />
                    Side Peek
                  </button>
                </div>
              )}
            </div>

            {onDelete && (
              <button onClick={openDeleteModal} className="text-gray-500 hover:text-gray-800">
                <Icon name="Trash2" size="md" />
              </button>
            )}
            <button onClick={onCancel} className="text-gray-500 hover:text-gray-800">
              <Icon name="X" size="md" />
            </button>
          </div>
        </div>

        {/* Modal Body */}
        <div className="flex flex-grow overflow-hidden">
          {/* Main Content */}
          <div className={`flex-grow overflow-y-auto p-6 ${isSidePanelOpen ? 'w-2/3' : 'w-full'}`}>
            {/* Title Input */}
            {showTitle && (
              <div className="mb-4">
                <InputTitle
                  placeholder={titleInputPlaceholder}
                  value={localTitle}
                  onChange={(value) => {
                    setLocalTitle(value);
                    if (onTitleChange) onTitleChange(value);
                  }}
                  onBlur={onTitleBlur}
                />
              </div>
            )}

            {/* Dynamic Form Fields */}
            <div className="mb-4 flex flex-col gap-4">
              {formFields.map((field, index) => {
                const FormComponent = field.component;
                return <FormComponent key={index} {...field.props} />;
              })}
            </div>

            {/* Dynamic Select Components */}
            <div className="mb-4 flex flex-wrap gap-4">
              {selects.map((select, index) => {
                const SelectComponent = select.component;
                return <SelectComponent key={index} {...select.props} />;
              })}
            </div>

            {/* Separator - only show if there's content after it */}
            {(showDescription || children) && (
              <SeparatorHorizontal className="my-6" />
            )}

            {/* Description Input */}
            {showDescription && (
              <div className={`mb-4 ${descriptionHeightClass}`}>
                <MarkDownEditor
                  placeholder={descriptionPlaceholder}
                  initialContent={localDescription}
                  onChange={(value) => {
                    setLocalDescription(value);
                    if (onDescriptionChange) onDescriptionChange(value);
                  }}
                  onBlur={onDescriptionBlur}
                  parentObjectType={parentObjectType}
                  parentObjectId={parentObjectId}
                  menuType='combined'
                />
              </div>
            )}

            {/* Render Children Here */}
            {children}

            {/* Footer */}
            <div className="border-t border-gray-200 p-4">
              <div className="flex items-center justify-between">
                {/* Create More Toggle Switch */}
                {showCreateMoreToggle && (
                  <div className="flex items-center">
                    <ToggleSwitch
                      checked={createMore}
                      onChange={handleCreateMoreChange}
                      label="Create more"
                      labelPosition="right"
                    />
                  </div>
                )}

                {/* Footer Buttons */}
                {showFooterButtons && (
                  <div className="flex space-x-4">
                    <Button
                      label="Cancel"
                      variant="secondary"
                      buttonStyle="outlined"
                      onClick={onCancel}
                    />
                    <Button
                      label={actionButtonLabel}
                      variant="primary"
                      onClick={handleSubmit}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Activity Feed */}
            {showActivityFeed && (
              <div className="mt-4">
                {activityFeed}
              </div>
            )}
          </div>

          {/* Side Panel */}
          {isSidePanelOpen && (
            <div className="w-1/3 h-full overflow-y-auto flex flex-col border-l border-gray-200">
              {sidePanelContent}
            </div>
          )}
        </div>

        {/* Delete Modal */}
        {isDeleteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-60">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
              <p>Are you sure you want to delete this item?</p>
              <div className="mt-4 flex justify-end space-x-2">
                <Button label="Cancel" variant="secondary" onClick={closeDeleteModal} />
                <Button label="Delete" variant="danger" onClick={handleDeleteConfirm} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalContent;
