// Utility to handle localStorage operations generically
class LocalStorageUtils {
    /**
     * Save data to localStorage.
     * Automatically stringifies the value before saving.
     * @param key - The key under which the value is stored.
     * @param value - The value to store.
     */
    static setItem<T>(key: string, value: T): void {
      try {
        const jsonValue = JSON.stringify(value);
        localStorage.setItem(key, jsonValue);
      } catch (error) {
        console.error(`Error saving key "${key}" to localStorage:`, error);
      }
    }
  
    /**
     * Get data from localStorage.
     * Automatically parses the value if it exists.
     * @param key - The key of the value to retrieve.
     * @returns The parsed value or null if not found.
     */
    static getItem<T>(key: string): T | null {
      try {
        const jsonValue = localStorage.getItem(key);
        return jsonValue ? JSON.parse(jsonValue) : null;
      } catch (error) {
        console.error(`Error retrieving key "${key}" from localStorage:`, error);
        return null;
      }
    }
  
    /**
     * Remove data from localStorage.
     * @param key - The key of the value to remove.
     */
    static removeItem(key: string): void {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error(`Error removing key "${key}" from localStorage:`, error);
      }
    }
  
    /**
     * Clear all data from localStorage.
     * Use with caution as it removes all stored data.
     */
    static clear(): void {
      try {
        localStorage.clear();
      } catch (error) {
        console.error("Error clearing localStorage:", error);
      }
    }
  }
  
  export default LocalStorageUtils;
  