import axios, { AxiosRequestConfig, InternalAxiosRequestConfig, AxiosHeaders } from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000/api/';

const api = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

// Utility function to clear cookies
const clearCookies = (): void => {
    document.cookie.split(';').forEach((cookie) => {
        document.cookie = `${cookie.trim().split('=')[0]}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
};

// Function to initialize CSRF token
export const initializeCsrf = async (): Promise<void> => {
    try {
        await api.get('users/csrf/');
    } catch (error) {
        console.error('Failed to initialize CSRF token:', error);
    }
};

// Add request interceptor to include CSRF token
api.interceptors.request.use((config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.method && ['post', 'put', 'patch', 'delete'].includes(config.method.toLowerCase())) {
        const cookies = document.cookie.split(';').reduce((acc: Record<string, string>, cookie) => {
            const [name, value] = cookie.split('=');
            acc[name.trim()] = value?.trim() || '';
            return acc;
        }, {});

        const csrfToken = cookies.csrftoken;
        if (csrfToken) {
            if (!config.headers) {
                config.headers = new AxiosHeaders();
            }
            config.headers.set('X-CSRFToken', csrfToken);
        }
    }
    return config;
});

// Logout function
export const logout = async (): Promise<void> => {
    try {
        await api.post('logout/');
        localStorage.clear();
        clearCookies();
    } catch (error) {
        console.error('Logout failed:', error);
    } finally {
        window.location.href = '/login';
    }
};

// Request a magic link
export const requestMagicLink = async (email: string): Promise<any> => {
    const response = await api.post('login/magic-link/', { email });
    return response.data;
};

// Validate magic link token
export const validateMagicLink = async (token: string): Promise<any> => {
    const response = await api.post('login/magic-link/validate/', { token });
    return response.data;
};

// Validate magic code
export const validateMagicCode = async (email: string, code: string): Promise<any> => {
    const response = await api.post('login/magic-code/validate/', { email, code });
    return response.data;
};

// Interceptor for session-based authentication and CSRF handling
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401) {
            console.error('Session expired. Clearing cookies and reinitializing CSRF token.');
            clearCookies();
            try {
                await initializeCsrf(); // Refresh CSRF token
            } catch (csrfError) {
                console.error('Failed to refresh CSRF token:', csrfError);
                await logout(); // Force logout if CSRF cannot be refreshed
            }
        }
        return Promise.reject(error);
    }
);

export default api;
