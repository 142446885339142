import React, { useState } from 'react';
import { Topic } from '../../api/topic/types';
import { Icon, Button, Snackbar, SidePanelItem } from '../ui';
import TopicContainer from '../../containers/TopicContainer';
import { TopicModal } from '../topic/TopicModal';
import { useQueryClient } from 'react-query';
import { entityStyles, topicStateOptions, heatmapStatusOptions, importanceOptions } from '../../utils/entitiesOptions';

interface TopicPanelProps {
  isOpen: boolean;
  onClose?: () => void;
  meetingTypeId?: string;
  personId?: string;
  onTopicClick?: (topic: Topic) => void;
  title?: string;
  showAddTopicButton?: boolean;
}

const TopicPanel: React.FC<TopicPanelProps> = ({
  isOpen,
  onClose,
  meetingTypeId,
  personId,
  onTopicClick,
  title = 'Topics',
  showAddTopicButton = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [topicToEdit, setTopicToEdit] = useState<Topic | null>(null);
  const queryClient = useQueryClient();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');

  const openTopicCreateModal = () => {
    setTopicToEdit(null);
    setShowModal(true);
  };

  const handleTopicClick = (topic: Topic) => {
    setTopicToEdit(topic);
    setShowModal(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
        {showAddTopicButton && (
          <Button
            size="medium"
            variant="primary"
            label="Add New Topic"
            onClick={openTopicCreateModal}
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        <TopicContainer
          meetingTypeId={meetingTypeId}
          personId={personId}
          render={({ data: topicsFromContainer, loading, error }) => {
            const sortedTopics = [...topicsFromContainer].sort((a, b) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            return (
              <>
                {loading && (
                  <div className="flex items-center justify-center py-4">
                    <Icon name="Loader2" size="sm" className="animate-spin" />
                  </div>
                )}
                {error && <div className="p-4 text-red-500">Error: {error}</div>}
                {!loading && !error && sortedTopics.length === 0 && (
                  <div className="p-4 text-gray-500 text-sm">No topics associated</div>
                )}

                {!loading && !error && sortedTopics.length > 0 && (
                  <div className="space-y-3">
                    {sortedTopics.map((topic) => {
                      const stateInfo = entityStyles.TOPIC.getStateInfo(topic.state);
                      
                      return (
                        <SidePanelItem
                          key={topic.id}
                          title={topic.title}
                          icon={entityStyles.TOPIC.icon}
                          prefix={entityStyles.TOPIC.prefix}
                          metadata={{
                            owner: topic.created_by.name,
                            state: topicStateOptions.find(option => option.value === topic.state)?.label,
                            heatmap_status: heatmapStatusOptions.find(option => option.value === topic.heatmap_status)?.label,
                            importance: importanceOptions.find(option => option.value === topic.importance.toString())?.label,
                          }}
                          onClick={() => handleTopicClick(topic)}
                          borderColor="border-l-purple-500"
                        >
                          <div className="flex items-center gap-2 text-xs text-gray-400 mt-1">
                            <div className="flex items-center gap-1">
                              <Icon name="Activity" size="sm" />
                              <span>Heatmap: {topic.heatmap_status}</span>
                            </div>
                            <div className="flex items-center gap-1">
                              <Icon name="Signal" size="sm" />
                              <span>Importance: {topic.importance}</span>
                            </div>
                          </div>
                        </SidePanelItem>
                      );
                    })}
                  </div>
                )}

                {showModal && (
                  <TopicModal
                  isOpen={showModal}
                  initialData={topicToEdit || undefined}
                  onClose={() => setShowModal(false)}
                  onSuccess={async (message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('success');
                      setShowSnackbar(true);
                      setShowModal(false);
              
                      // Ensure UI waits for the cache to refresh
                      await queryClient.invalidateQueries(['topics', { personId, meetingTypeId }]);
                  }}
                  onError={(message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('error');
                      setShowSnackbar(true);
                  }}
                  parentModalViewMode="fullscreen"
                  personId={personId}
              />
              
              
                )}
              </>
            );
          }}
        />

        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}
      </div>
    </div>
  );
};

export default TopicPanel;