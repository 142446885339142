import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MeetingTypeContainer from '../containers/MeetingTypeContainer';
import MeetingTypeTable from '../components/meeting/MeetingTypeTable';
import MeetingTypeModal from '../components/meeting/MeetingTypeModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { MeetingType } from '../api/meeting/types';

const MeetingTypeListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [meetingTypeToDelete, setMeetingTypeToDelete] = useState<string | null>(null);
  const [meetingTypeToEdit, setMeetingTypeToEdit] = useState<MeetingType | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]); // Store meeting types data

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>(); // Get slug from URL

  // Effect to open modal if slug is present
  useEffect(() => {
    if (slug && meetingTypes.length > 0) {
      const meetingType = meetingTypes.find((type) => type.slug === slug);
      if (meetingType) {
        setMeetingTypeToEdit(meetingType);
        setShowModal(true);
      }
    }
  }, [slug, meetingTypes]);

  const handleDelete = (slug: string) => {
    setMeetingTypeToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (meetingTypeToDelete) {
      onDelete(meetingTypeToDelete);
      setShowDeleteDialog(false);
      setMeetingTypeToDelete(null);
      handleSuccess('Meeting Type deleted successfully');
      navigate('/meeting-types/');
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    setMeetingTypeToEdit(null);
    setShowModal(true);
    navigate('/meeting-types/new');
  };

  const handleEditMeetingType = (meetingType: MeetingType) => {
    navigate(`/meeting-types/${meetingType.slug}`);
    setMeetingTypeToEdit(meetingType);
    setShowModal(true);
  };

  const handleSuccess = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleError = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarType('error');
    setShowSnackbar(true);
  };

  return (
    <div>
      <h1 className='m-4'>Meeting Types</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search Meeting Types..."
        addButtonLabel="Add New Meeting Type"
      />

      <MeetingTypeContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading meeting types...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          // Set meeting types on initial load
          if (meetingTypes.length === 0 && data.length > 0) {
            setMeetingTypes(data);
          }

          const filteredMeetingTypes = data.filter(meetingType =>
            meetingType.name.toLowerCase().includes(searchTerm.toLowerCase())
          );

          // Move handleSaveMeetingType inside render to access onCreate and onEdit
          const handleSaveMeetingType = (
            meetingTypeData: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>,
            slug?: string
          ) => {
            // If `slug` is provided, it's an edit, otherwise, it's a creation
            if (slug) {
              onEdit(slug, meetingTypeData);
              handleSuccess('Meeting Type updated successfully');
            } else {
              onCreate(meetingTypeData);
              handleSuccess('Meeting Type created successfully');
            }
          };

          return (
            <>
              {/* Meeting Type Modal */}
              {showModal && (
                <MeetingTypeModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    navigate('/meeting-types/');
                  }}
                  onSave={meetingTypeToEdit ? (data) => handleSaveMeetingType(data, meetingTypeToEdit.slug) : handleSaveMeetingType}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onSuccess={handleSuccess}
                  initialData={meetingTypeToEdit || undefined}
                />
              )}

              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this meeting type?</p>
              </Modal>

              {/* Meeting Type Table */}
              <MeetingTypeTable
                data={filteredMeetingTypes}
                key={filteredMeetingTypes.map((meetingType) => meetingType.slug).join()}
                onEdit={handleEditMeetingType}
                onDelete={handleDelete}
              />
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default MeetingTypeListPage;
