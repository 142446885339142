import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TaskContainer from '../containers/TaskContainer';
import { TaskTable } from '../components/task/TaskTable';
import { TaskModal } from '../components/task/TaskModal';
import { Modal, TableHeader, Snackbar, Button } from '../components/ui';
import { Task } from '../api/task/types';
import { Tag } from '../api/tag/types';
import { Person } from '../api/person/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { FilterConfig } from '../components/ui/data/Filter';
import { SeparatorHorizontal } from '../components/ui';
import { useUser } from '../contexts/UserContext';
import { TaskBoardView } from '../components/task/TaskBoardView'
import { TaskStateBoardView } from '../components/task/TaskStateBoardView';
import { Project } from '../api/project/types';
import { Topic } from '../api/topic/types';

const TaskListPage: React.FC = () => {
  const { personId } = useUser(); // Use user ID for the assigned filter
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<string | null>(null);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [showCompleted, setShowCompleted] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>('dial_stage');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [viewMode, setViewMode] = useState<'table' | 'weekly' | 'state'>('table');
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const [projects, setProjects] = useState<Project[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);

  useEffect(() => {
    if (slug && tasks.length > 0) {
      const task = tasks.find((task) => task.slug === slug);
      if (task) {
        setTaskToEdit(task);
        setShowModal(true);
      }
    }
  }, [slug, tasks]);

  useEffect(() => {
    if (!showModal) {
      setViewMode(location.pathname === "/tasks/board" ? 'weekly' : location.pathname === "/tasks/state" ? 'state' : 'table');
    }
  }, [location.pathname, showModal]);
  
  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => {
          setViewMode('table');
          navigate('/tasks/');
        }}
        variant={viewMode === 'table' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Table View"
        iconColor="white"
      />
      <Button
        icon="Layout"
        onClick={() => {
          setViewMode('weekly');
          navigate('/tasks/board');
        }}
        variant={viewMode === 'weekly' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Weekly View"
        iconColor="white"
      />
      <Button
        icon="Columns"
        onClick={() => {
          setViewMode('state');
          navigate('/tasks/state');
        }}
        variant={viewMode === 'state' ? 'primary' : 'secondary'}
        iconSize="md"
        title="State View"
        iconColor="white"
      />
    </>
  );
  

  // Determine initial filters based on URL path
  useEffect(() => {
    const path = location.pathname;
    let newFilter: Record<string, any> = {};

    if (path.includes("/tasks/active")) {
      newFilter = { state: ["IN_PROGRESS"] };
      setShowCompleted(false);
    } else if (path.includes("/tasks/backlog")) {
      newFilter = { state: ["IDENTIFIED"] };
      setShowCompleted(false);
    } else if (path.includes("/tasks/complete")) {
      newFilter = { state: ["COMPLETED"] };
      setShowCompleted(true);
    } else if (path.includes("/tasks/overdue")) {
      newFilter = { due_date: "overdue" };
      setShowCompleted(false);
    } else if (path.includes("/tasks/assigned") && personId) {
      newFilter = { owner: personId };
      setShowCompleted(false);
    } else if (path.includes("/tasks/all")) {
      newFilter = {};
    }

    setFilters(newFilter);
  }, [location.pathname, personId]);

  const handleShowCompletedToggle = () => {
    setShowCompleted(!showCompleted);
  };

  // Sync filters with TableHeader for display
  useEffect(() => {
  }, [filters]);

  const handleUpdateTitle = (
    slug: string,
    newTitle: string,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { title: newTitle });
    setSnackbarMessage('Task title updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateState = (
    slug: string,
    newState: Task['state'],
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { state: newState });
    setSnackbarMessage('Task state updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateDialStage = (
    slug: string,
    newDialStage: Task['dial_stage'],
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { dial_stage: newDialStage });
    setSnackbarMessage('Task dial stage updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateOwner = (
    slug: string,
    newOwner: Person | null,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { owner: newOwner || undefined });
    setSnackbarMessage('Task owner updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const validateDates = (task: Task, field: 'start_date' | 'due_date', newDate: string): boolean => {
    const startDate = field === 'start_date' ? newDate : task.start_date;
    const dueDate = field === 'due_date' ? newDate : task.due_date;

    if (startDate && dueDate && new Date(startDate) > new Date(dueDate)) {
      setSnackbarMessage('Start date cannot be after due date');
      setSnackbarType('error');
      setShowSnackbar(true);
      return false;
    }
    return true;
  };

  const handleUpdateStartDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    const task = tasks.find(t => t.slug === slug);
    if (task && validateDates(task, 'start_date', newDate)) {
      onEdit(slug, { start_date: newDate });
      setSnackbarMessage('Task start date updated successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    }
  };

  const handleUpdateDueDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    const task = tasks.find(t => t.slug === slug);
    if (task && validateDates(task, 'due_date', newDate)) {
      onEdit(slug, { due_date: newDate });
      setSnackbarMessage('Task due date updated successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    }
  };

  const handleUpdateTags = (
    slug: string,
    newTags: Tag[],
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { tags: newTags });
    setSnackbarMessage('Task tags updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleDelete = (slug: string) => {
    setTaskToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (taskToDelete) {
      onDelete(taskToDelete);
      setShowDeleteDialog(false);
      setTaskToDelete(null);
      setSnackbarMessage('Task deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleEditTask = (task: Task) => {
    const searchParams = new URLSearchParams(location.search);
    // If there's no mode parameter, set a default (e.g., 'c' for modal)
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
    navigate(`/tasks/${task.slug}?${searchParams.toString()}`);
    setTaskToEdit(task);
    setShowModal(true);
  };
  
  const handleAddNew = () => {
    setTaskToEdit(null);
    setShowModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); // default to modal
    }
    navigate(`/tasks/new?${searchParams.toString()}`);
  };

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'State', value: 'state' },
    { label: 'Dial Stage', value: 'dial_stage' },
    { label: 'Due Date', value: 'due_date' },
    { label: 'Created Date', value: 'created_at' },
  ];

  const compareValues = (a: any, b: any) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const compareDates = (dateA: string | null | undefined, dateB: string | null | undefined): number => {
    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  };

  const dialStageOrder = ['MENTION', 'INVITATION', 'CONVERSATION', 'BOUNDARY', 'LIMIT'];

  const getDialStageValue = (stage: string | undefined): number => {
    if (!stage) return -1;
    const index = dialStageOrder.indexOf(stage);
    return index === -1 ? dialStageOrder.length : index;
  };

  const compareDialStages = (a: string | undefined, b: string | undefined): number => {
    return getDialStageValue(a) - getDialStageValue(b);
  };

  // Define filter configuration
  const filterConfig: FilterConfig = useMemo(() => {
    const allTags = Array.from(new Set(tasks.flatMap(task => task.tags.map(tag => tag.name))));
    const allOwners = Array.from(new Set(tasks.map(task => task.owner?.name).filter((name): name is string => name !== undefined)));
    const allProjects = Array.from(new Set(tasks.map(task => task.project?.title).filter((name): name is string => name !== undefined)));
    const allTopics = Array.from(new Set(tasks.map(task => task.topic?.title).filter((name): name is string => name !== undefined)));

    return {
      state: {
        label: 'State',
        value: 'state',
        type: 'select',
        options: [
          { label: 'Identified', value: 'IDENTIFIED' },
          { label: 'In Progress', value: 'IN_PROGRESS' },
          { label: 'Completed', value: 'COMPLETED' },
          { label: 'Archived', value: 'ARCHIVED' }
        ],
      },
      dial_stage: {
        label: 'Dial Stage',
        value: 'dial_stage',
        type: 'select',
        options: [
          { label: 'Mention', value: 'MENTION' },
          { label: 'Invitation', value: 'INVITATION' },
          { label: 'Conversation', value: 'CONVERSATION' },
          { label: 'Boundary', value: 'BOUNDARY' },
          { label: 'Limit', value: 'LIMIT' }
        ],
      },
      tags: {
        label: 'Tags',
        value: 'tags',
        type: 'multi-select',
        options: allTags.map(tag => ({ label: tag, value: tag })),
      },
      owner: {
        label: 'Owner',
        value: 'owner',
        type: 'select',
        options: allOwners.map(owner => ({ label: owner, value: owner })),
      },
      project: {
        label: 'Project',
        value: 'project',
        type: 'select',
        options: allProjects.map(project => ({ label: project, value: project })),
      },
      topic: {
        label: 'Topic',
        value: 'topic',
        type: 'select',
        options: allTopics.map(topic => ({ label: topic, value: topic })),
      },
    };
  }, [tasks]);

  // Handle filter changes
  const handleFilterChange = (newFilters: Record<string, any>) => {
    setFilters(newFilters);
  };

  const filterAndSortTasks = (tasks: Task[]): Task[] => {
    const currentDate = new Date();
  
    let filteredTasks = tasks.filter(task =>
      task.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (showArchived || task.state !== 'ARCHIVED') &&
      (showCompleted || task.state !== 'COMPLETED') &&
      Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
  
        switch (key) {
          case 'state':
            return Array.isArray(value) ? value.includes(task.state) : task.state === value;
          case 'dial_stage':
            return task.dial_stage === value;
          case 'tags':
            return Array.isArray(value) ? value.some(tag => task.tags.some(taskTag => taskTag.name === tag)) : true;
          case 'owner':
            return task.owner?.id === value;
          case 'start_date':
            return task.start_date === value;
          case 'due_date':
            return value === "overdue" ? (task.due_date && new Date(task.due_date) < currentDate) : true;
          case 'project':
            return task.project?.title === value;
            case 'topic':
            return task.topic?.title === value;
          default:
            return true;
        }
      })
    );
  
    return filteredTasks.sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case 'title':
          result = a.title.localeCompare(b.title);
          break;
        case 'state':
          result = a.state.localeCompare(b.state);
          break;
        case 'dial_stage':
          result = compareDialStages(a.dial_stage, b.dial_stage);
          break;
        case 'start_date':
          result = compareDates(a.start_date, b.start_date);
          break;
        case 'due_date':
          result = compareDates(a.due_date, b.due_date);
          break;
        case 'created_at':
          result = compareDates(a.created_at, b.created_at);
          break;
        default:
          break;
      }
      return sortDirection === 'asc' ? result : -result;
    });
  };

  return (
    <div>
      <h1 className="m-4">
        {viewMode === 'table' 
          ? "Tasks" 
          : viewMode === 'weekly'
          ? "Tasks - Weekly View"
          : "Tasks - State View"}
      </h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        onShowArchive={() => setShowArchived(!showArchived)}
        onShowComplete={handleShowCompletedToggle}
        searchPlaceholder="Search Tasks..."
        addButtonLabel="Add New Task"
        showArchived={showArchived}
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        filterConfig={filterConfig}
        onFilterChange={handleFilterChange}
        initialFilter={filters}
        additionalButtons={additionalButtons}
      />

      <TaskContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading tasks...</p>
            </div>
          );
          
          if (error) return <div>{error}</div>;

          if (tasks.length === 0 && data.length > 0) {
            setTasks(data);
          }

          const filteredAndSortedTasks = filterAndSortTasks(data);

          return (
            <>
              {showModal && (
                <TaskModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    navigate(viewMode === 'weekly' ? '/tasks/board' : viewMode === 'state' ? '/tasks/state' : '/tasks/');
                  }}
                  initialData={taskToEdit ? { ...taskToEdit } : undefined}
                  onSuccess={(message) => {
                    setSnackbarMessage(message);
                    setSnackbarType('success');
                    setShowSnackbar(true);
                  }}
                  onError={(message) => {
                    setSnackbarMessage(message);
                    setSnackbarType('error');
                    setShowSnackbar(true);
                  }}
                />
              )}
              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this task?</p>
              </Modal>

              {viewMode === 'table' ? (
                <TaskTable
                  data={filteredAndSortedTasks}
                  key={filteredAndSortedTasks.map((task) => task.slug).join()}
                  onEdit={handleEditTask}
                  onDelete={(slug) => handleDelete(slug)}
                  onUpdateState={(slug, newState) => handleUpdateState(slug, newState, onEdit)}
                  onUpdateDialStage={(slug, newDialStage) => handleUpdateDialStage(slug, newDialStage, onEdit)}
                  onUpdateOwner={(slug, newOwner) => handleUpdateOwner(slug, newOwner, onEdit)}
                  onUpdateStartDate={(slug, newDate) => handleUpdateStartDate(slug, newDate, onEdit)}
                  onUpdateDueDate={(slug, newDate) => handleUpdateDueDate(slug, newDate, onEdit)}
                  onUpdateTags={(slug, newTags) => handleUpdateTags(slug, newTags, onEdit)}
                  onUpdateTitle={(slug, newTitle) => handleUpdateTitle(slug, newTitle, onEdit)}
                />
              ) : viewMode === 'weekly' ? (
                <TaskBoardView 
                  tasks={filteredAndSortedTasks}
                  onOpenTask={(task) => {
                    // Preserve mode
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c'); // Default to 'c' for modal if none is present
                    }

                    setTaskToEdit(task);
                    setShowModal(true);
                    navigate(`/tasks/${task.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={onEdit}
                />
              ) : (
                <TaskStateBoardView
                  tasks={filteredAndSortedTasks}
                  onOpenTask={(task) => {
                    // Preserve mode
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c'); // Default to 'c' for modal if none is present
                    }

                    setTaskToEdit(task);
                    setShowModal(true);
                    navigate(`/tasks/${task.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={onEdit}
                />
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default TaskListPage;
