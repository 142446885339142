import React, { useState } from 'react';
import { Topic } from '../../api/topic/types';
import { Icon } from '../ui';
import TopicSelect from './TopicSelect';

interface TopicSelectPanelProps {
  isOpen: boolean;
  onClose?: () => void;
  meetingTypeId?: string;
  personId?: string;
  selectedTopics: Topic[];
  onTopicSelect: (topic: Topic) => void;
  onTopicDeselect: (topicId: string) => void;
  title?: string;
  maxSelections?: number;
}

const TopicSelectPanel: React.FC<TopicSelectPanelProps> = ({
  isOpen: panelIsOpen,
  onClose: onPanelClose,
  meetingTypeId,
  personId,
  selectedTopics,
  onTopicSelect,
  onTopicDeselect,
  title = 'Select Topics',
  maxSelections,
}) => {
  // Separate state for the select dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleTopicSelect = (topic: Topic) => {
    if (maxSelections && selectedTopics.length >= maxSelections) {
      return;
    }
    onTopicSelect(topic);
    setDropdownOpen(false);  // Close dropdown after selection
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  // Function to open the dropdown
  const handleDropdownOpen = () => {
    setDropdownOpen(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
        {maxSelections && (
          <span className="text-sm text-gray-500">
            {selectedTopics.length}/{maxSelections} selected
          </span>
        )}
      </div>

      <div className="p-4 border-b">
        <div onClick={handleDropdownOpen} className="cursor-pointer">
          <TopicSelect
            value=""
            onChange={handleTopicSelect}
            isDisabled={maxSelections ? selectedTopics.length >= maxSelections : false}
            placeholder="Search and select topics..."
            
            personId={personId}
            isOpen={dropdownOpen}
            onClose={handleDropdownClose}
            enableSearch={true}
          />
        </div>
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        {selectedTopics.length === 0 ? (
          <div className="text-center text-gray-500 py-8">
            <Icon name="List" size="lg" className="mx-auto mb-2" />
            <p>No topics selected</p>
            <p className="text-sm mt-1">Use the search above to add topics</p>
          </div>
        ) : (
          <div className="space-y-2">
            {selectedTopics.map((topic) => (
              <div
                key={topic.id}
                className="flex items-center justify-between p-3 bg-gray-50 rounded-md group hover:bg-gray-100"
              >
                <div className="flex items-center space-x-2">
                  <Icon name="MessageSquare" size="sm" className="text-gray-400" />
                  <span className="font-medium">{topic.title}</span>
                </div>
                <button
                  onClick={() => onTopicDeselect(topic.id)}
                  className="opacity-0 group-hover:opacity-100 p-1 hover:bg-gray-200 rounded transition-opacity"
                >
                  <Icon name="X" size="sm" className="text-gray-500" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopicSelectPanel;