import { useState, useEffect, useCallback } from 'react';
import LocalStorageUtils from '../utils/localStorageUtils';

interface DescriptionDraft {
  description: string;
  lastModified: number;
}

interface UseDescriptionDraftOptions {
  entityType: string;
  entityId?: string;
  initialDescription?: string;
  isOpen: boolean;
}

// Utility function to check if HTML content is empty
const isHtmlContentEmpty = (html: string): boolean => {
  // Remove HTML tags
  const textContent = html.replace(/<[^>]*>/g, '');
  // Remove whitespace
  const trimmedContent = textContent.trim();
  return trimmedContent === '';
};

export const useDescriptionDraft = ({
  entityType,
  entityId,
  initialDescription = '',
  isOpen,
}: UseDescriptionDraftOptions) => {
  const [description, setDescription] = useState(initialDescription);
  const [hasUnsavedDraft, setHasUnsavedDraft] = useState(false);

  const getStorageKey = (id?: string) => {
    return `${entityType}_draft_${id || 'new'}`;
  };

  useEffect(() => {
    if (isOpen) {
      const storageKey = getStorageKey(entityId);
      const savedDraft = LocalStorageUtils.getItem<DescriptionDraft>(storageKey);

      if (savedDraft && !isHtmlContentEmpty(savedDraft.description)) {
        setDescription(savedDraft.description);
        setHasUnsavedDraft(true);
      } else {
        setDescription(initialDescription);
        setHasUnsavedDraft(false);
      }
    }
  }, [isOpen, entityId, initialDescription]);

  const handleDescriptionChange = useCallback((newDescription: string) => {
    setDescription(newDescription);

    if (!isHtmlContentEmpty(newDescription)) {
      const storageKey = getStorageKey(entityId);
      const draft: DescriptionDraft = {
        description: newDescription,
        lastModified: Date.now()
      };
      
      LocalStorageUtils.setItem(storageKey, draft);
    } else {
      clearDraft();
    }
  }, [entityId]);

  const clearDraft = useCallback(() => {
    const storageKey = getStorageKey(entityId);
    LocalStorageUtils.removeItem(storageKey);
    setDescription('');
    setHasUnsavedDraft(false);
  }, [entityId]);

  return {
    description,
    hasUnsavedDraft,
    handleDescriptionChange,
    clearDraft,
  };
};