import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent, Icon, IconName } from '../ui/';
import { SidePanelItem } from '../ui/panel/SidePanelItem';
import AlertContainer from '../../containers/AlertContainer';
import { Alert, AlertType } from '../../api/alert/types';
import { entityStyles, severityColors } from '../../utils/entitiesOptions';

// Alert-specific type mappings
const typeToIcon: Record<AlertType, { name: IconName; className: string }> = {
  'OVERDUE_TASK': { name: 'Clock', className: 'text-red-500' },
  'BLOCKED_TASK': { name: 'AlertTriangle', className: 'text-amber-500' },
  'CRITICAL_TOPIC': { name: 'AlertCircle', className: 'text-red-500' },
  'AT_RISK_PROJECT': { name: 'AlertOctagon', className: 'text-amber-500' },
  'GOAL_PROGRESS': { name: 'Target', className: 'text-amber-500' }
};

// Get the entity type from the alert type (e.g., "OVERDUE_TASK" -> "TASK")
const getEntityType = (alertType: AlertType): string => {
  if (alertType.includes('TASK')) return 'TASK';
  if (alertType.includes('TOPIC')) return 'TOPIC';
  if (alertType.includes('PROJECT')) return 'PROJECT';
  if (alertType.includes('GOAL')) return 'GOAL';
  return '';
};

// Map entity types to their routes
const getSlugPath = (entityType: string): string => {
  const paths: Record<string, string> = {
    'TASK': 'tasks',
    'TOPIC': 'topics',
    'PROJECT': 'projects',
    'GOAL': 'goals'
  };
  return paths[entityType] || '';
};

export const PulsePane = () => {
  const navigate = useNavigate();

  const handleAlertClick = (alert: Alert) => {
    const entityType = getEntityType(alert.type as AlertType);
    const path = getSlugPath(entityType);
    if (path && alert.metadata.slug) {
      navigate(`/${path}/${alert.metadata.slug}`);
    }
  };

  return (
    <AlertContainer
      render={({ data: alerts, loading, error }) => (
        <Card className="bg-white w-64 !p-0 !shadow-none !border-0">
          <CardHeader className="!px-4 !py-2">
            <div className="flex items-center mb-2 mt-2">
              <CardTitle className="text-md font-medium">
                <span>Pulse</span>
              </CardTitle>
            </div>
          </CardHeader>
          
          <CardContent className="!px-4 !py-2">
            {loading ? (
              <div className="flex items-center justify-center py-4">
                <Icon name="Loader2" size="sm" className="animate-spin" />
              </div>
            ) : error ? (
              <div className="text-sm text-red-500 text-center py-4">
                {error}
              </div>
            ) : alerts.length === 0 ? (
              <div className="text-sm text-gray-500 text-center py-4">
                No alerts at this time
              </div>
            ) : (
              <div className="space-y-3">
                {alerts.map((alert) => {
                  const entityType = getEntityType(alert.type as AlertType);
                  const entity = entityStyles[entityType];

                  return (
                    <SidePanelItem
                      key={alert.id}
                      title={alert.title}
                      message={alert.message}
                      // Use alert-specific icon instead of entity default
                      icon={typeToIcon[alert.type] || { name: 'Bell', className: '' }}
                      prefix={entity?.prefix}
                      borderColor={severityColors[alert.severity]}
                      metadata={{
                        project: alert.metadata.project || undefined,
                        dial_stage: alert.metadata.dial_stage || undefined,
                        owner: alert.metadata.owner || undefined
                      }}
                      onClick={() => handleAlertClick(alert)}
                    />
                  );
                })}
              </div>
            )}
          </CardContent>
        </Card>
      )}
    />
  );
};

export default PulsePane;