import { IconName } from '../components/ui';

export const typeToIcon: Record<string, { name: IconName; color: string }> = {
    topic: { name: 'Library', color: 'text-primary' },
    task: { name: 'ListChecks', color: 'text-primary' },
    project: { name: 'Layers', color: 'text-secondary' },
    person: { name: 'Users', color: 'text-accent' },
    strategy: { name: 'Flag', color: 'text-secondary' },
    goal: { name: 'Goal', color: 'text-primary' }
};

export const typeToTitle: Record<string, string> = {
    topic: 'Topics',
    accountability_dial_task: 'Tasks',
    project: 'Projects',
    person: 'People',
    strategy: 'Strategies',
    goal: 'Goals'
};

export const contentTypeMapping: Record<string, string> = {
    topic: 'topic.topic',
    task: 'accountability_dial.task',
    project: 'project.project',
    person: 'organization.person',
    strategy: 'strategy.strategy',
    goal: 'goals.goal',
    meeting: 'meeting.meeting_occurrence',
    meeting_type: 'meeting.meeting_type'
};

export const getSlugPath = (type: string): string => {
    const paths: Record<string, string> = {
        'topic.topic': 'topics',
        'accountability_dial.task': 'tasks',
        'project.project': 'projects',
        'organization.person': 'persons',
        'strategy.strategy': 'strategies',
        'goals.goal': 'goals',
        'meeting.meeting_occurrence': 'meetings',
        'meeting.meeting_type': 'meeting-types'
    };
    return paths[type] || '';
}; 