import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Initialize Sentry
Sentry.init({
  dsn: "https://2190b5515a300a7331ba358925becd2f@o4508544675282944.ingest.de.sentry.io/4508545622147152",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing configuration
  tracesSampleRate: 1.0, // Capture 100% of transactions
  tracePropagationTargets: ["localhost:8000/api", /^https:\/\/get-align\.io\/api/], // Include localhost API URL
  // Session Replay
  replaysSessionSampleRate: 0.1, // Capture 10% of sessions
  replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with errors
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
