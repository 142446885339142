import { useState, useEffect, useCallback } from 'react';
import LocalStorageUtils from '../utils/localStorageUtils';

interface CommentDraft {
  comment: string;
  lastModified: number;
}

interface UseCommentDraftOptions {
  entityType: string;
  parentId?: string;
  isOpen?: boolean;
}

export const useCommentDraft = ({
  entityType,
  parentId,
  isOpen = true
}: UseCommentDraftOptions) => {
  const [comment, setComment] = useState('');
  const [hasUnsavedDraft, setHasUnsavedDraft] = useState(false);

  const getStorageKey = useCallback(() => {
    return `comment_draft_${parentId || 'new'}_${entityType}`;
  }, [parentId, entityType]);

  useEffect(() => {
    if (isOpen) {
      const storageKey = getStorageKey();
      const savedDraft = LocalStorageUtils.getItem<CommentDraft>(storageKey);

      if (savedDraft) {
        setComment(savedDraft.comment);
        setHasUnsavedDraft(true);
      } else {
        setComment('');
        setHasUnsavedDraft(false);
      }
    }
  }, [isOpen, getStorageKey]);

  // Utility function to check if HTML content is empty
  const isHtmlContentEmpty = (html: string): boolean => {
    // Remove HTML tags
    const textContent = html.replace(/<[^>]*>/g, '');

    // Remove whitespace
    const trimmedContent = textContent.trim();
    return trimmedContent === '';
  };
  
  const handleCommentChange = useCallback((newComment: string) => {
    setComment(newComment);
    const storageKey = getStorageKey();
  
    if (!isHtmlContentEmpty(newComment)) {
      const draft: CommentDraft = {
        comment: newComment,
        lastModified: Date.now()
      };
      LocalStorageUtils.setItem(storageKey, draft);
    } else {
      clearDraft();
    }
  }, [getStorageKey]);

  const clearDraft = useCallback(() => {
    const storageKey = getStorageKey();
    LocalStorageUtils.removeItem(storageKey);
    setComment('');
    setHasUnsavedDraft(false);
  }, [getStorageKey]);

  return {
    comment,
    hasUnsavedDraft,
    handleCommentChange,
    clearDraft,
  };
};