import React from 'react';
import { Tabs } from '../ui';
import TaskPanel from '../task/TaskPanel';
import MeetingPastOccurrencesPanel from '../meeting/MeetingPastOccurrencesPanel';
import TopicPanel from '../topic/TopicPanel';
import ProjectPanel from '../project/ProjectPanel';

interface PersonSidePanelProps {
  personId: string;
}

export function PersonSidePanel({
  personId,
}: PersonSidePanelProps) {
  const tabs = [
    {
      label: 'Tasks',
      content: (
        <TaskPanel
          isOpen={true}
          personId={personId}
          showAddTaskButton={true}
        />
      ),
    },
    {
      label: 'Topics',
      content: (
        <TopicPanel
          isOpen={true}
          personId={personId}
        />
      ),
    },
    {
      label: 'Projects',
      content: (
        <ProjectPanel
          isOpen={true}
          personId={personId}
        />
      ),
    },
    {
      label: 'Meetings',
      content: (
        <MeetingPastOccurrencesPanel
          isOpen={true}
          attendeeId={personId}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full max-w-md p-4">
      <Tabs tabs={tabs} />
    </div>
  );
}

export default PersonSidePanel;