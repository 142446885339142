import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ModalContent, SelectSingle, Datepicker, SeparatorHorizontal, Button, Icon } from '../ui'; 
import { MeetingOccurrence, MeetingType, TopicNoteOperation } from '../../api/meeting/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import PersonSelectMulti from '../person/PersonSelectMulti';
import TagSelect from '../tags/TagSelect';
import { SelectOption } from '../ui/Select';
import { useNavigate } from 'react-router-dom';
import MeetingTypeContainer from '../../containers/MeetingTypeContainer';
import { useMeetingType } from '../../api/meeting/queries';
import { meetingOccurrenceStateOptions } from '../../utils/entitiesOptions';
import MeetingTypeSelect from './MeetingTypeSelect';
import MeetingOccurrenceSidePanel from './MeetingOccurrenceSidePanel';
import { AccordionList, AccordionItem } from '../ui/AccordionList';
import { useDescriptionDraft } from '../../hooks/useDescriptionDraft';

interface MeetingOccurrenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    occurrenceData: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc'> & {
      topic_notes_operations?: TopicNoteOperation[];
    },
    slug?: string
  ) => Promise<MeetingOccurrence>;
  onEdit: (
    slug: string, 
    updatedOccurrence: Partial<MeetingOccurrence> & {
      topic_notes_operations?: TopicNoteOperation[];
    }
  ) => Promise<MeetingOccurrence>;
  initialData?: Omit<MeetingOccurrence, 'created_at' | 'updated_at'>;
  onSuccess?: (message: string) => void;
  onError?: (message: string) => void;
}

export const MeetingOccurrenceModal: React.FC<MeetingOccurrenceModalProps> = ({ 
  isOpen, 
  onClose, 
  onSave, 
  onEdit, 
  initialData,
  onSuccess,
  onError 
}) => {

  // Add useDescriptionDraft hook for new occurrences only
  const {
    description: draftDescription,
    hasUnsavedDraft,
    handleDescriptionChange: handleDraftChange,
    clearDraft
  } = useDescriptionDraft({
    entityType: 'meeting_occurrence',
    entityId: initialData?.id,
    initialDescription: '',
    isOpen: isOpen && !initialData  // Only active for new occurrences
  });

  const [occurrenceData, setOccurrenceData] = useState<Partial<MeetingOccurrence>>({
    date: new Date().toISOString().split('T')[0],
    attendees: [],
    tags: [],
    meeting_type: initialData?.meeting_type || undefined,
    projects: [],
    state: initialData?.state || 'SCHEDULED'
  });
  const [title, setTitle] = useState(initialData?.title || '');
  const [description, setDescription] = useState('');
  const [meetingTypeSlug, setMeetingTypeSlug] = useState<string | undefined>(undefined);
  const [savedOccurrenceId, setSavedOccurrenceId] = useState<string | undefined>(initialData?.id);
  const [accordionItems, setAccordionItems] = useState<AccordionItem[]>([]);
  const [deletedTopicIds, setDeletedTopicIds] = useState<string[]>([]);
  const initialTopicNotesRef = useRef<Record<string, string>>({});

  const isInitialMount = useRef(true);
  const initialTitleRef = useRef('');
  const initialDescriptionRef = useRef('');
  const navigate = useNavigate();

  const { data: meetingTypeDetails } = useMeetingType(meetingTypeSlug || "");

  const isEditMode = !!initialData;

  // Initialize accordion items from initial data
  useEffect(() => {
    if (isInitialMount.current) {
      if (initialData) {
        // Set all meeting data
        setOccurrenceData({
          meeting_type: initialData.meeting_type,
          date: initialData.date,
          attendees: initialData.attendees || [],
          tags: initialData.tags || [],
          projects: initialData.projects || [],
          state: initialData.state || 'SCHEDULED',
          tasks: initialData.tasks || [],
        });
  
        setTitle(initialData.title);
        setDescription(initialData.description || '');
        initialTitleRef.current = initialData.title;
        initialDescriptionRef.current = initialData.description || '';
        
        // Set meeting type slug for fetching details
        if (initialData.meeting_type && typeof initialData.meeting_type === 'object') {
          setMeetingTypeSlug(initialData.meeting_type.slug);
        }
  
        // Handle topic notes if they exist
        if (initialData.topic_notes?.length) {
          const items = initialData.topic_notes.map(note => ({
            id: note.topic.id,
            title: note.topic.title,
            content: note.notes,
            descriptionPlaceholder: `Add notes for ${note.topic.title}`,
          }));
          setAccordionItems(items);
          
          // Store initial notes for comparison
          const initialNotes: Record<string, string> = {};
          initialData.topic_notes.forEach(note => {
            initialNotes[note.topic.id] = note.notes;
          });
          initialTopicNotesRef.current = initialNotes;
        } else {
          // Initialize with empty arrays if no topic notes
          setAccordionItems([]);
          initialTopicNotesRef.current = {};
        }
  
        setSavedOccurrenceId(initialData.id);
      }
      
      isInitialMount.current = false;
    }
  }, [initialData]);

  useEffect(() => {
    if (isOpen && !initialData) {
      setDescription(draftDescription || '');
    }
  }, [isOpen, initialData, draftDescription]);

  // Set tags and attendees based on meeting type for create mode
  useEffect(() => {
    if (meetingTypeDetails && !isEditMode) {
      // Set tags and attendees based on meeting type for create mode
      setOccurrenceData(prev => ({
        ...prev,
        attendees: meetingTypeDetails.regular_attendees || [],
        tags: meetingTypeDetails.tags || [],
      }));
  
      // Set the description only if it's empty
      if (!description || description.trim() === '') {
        setDescription(meetingTypeDetails.description || '');
      }
  
      // // Also set backlog topics if they exist
      // if (meetingTypeDetails.backlog_topics?.length) {
      //   const newAccordionItems = meetingTypeDetails.backlog_topics.map((topic) => ({
      //     id: `accordion-${topic.id}`,
      //     title: topic.title,
      //     content: '',
      //     descriptionPlaceholder: `Enter description for ${topic.title}`,
      //   }));
  
      //   const newTopicMap: Record<string, Topic> = meetingTypeDetails.backlog_topics.reduce((acc, topic) => {
      //     acc[`accordion-${topic.id}`] = topic;
      //     return acc;
      //   }, {} as Record<string, Topic>);
  
      //   setAccordionItems(newAccordionItems);
      //   setTopicMap(newTopicMap);
      // }
    }
  }, [meetingTypeDetails, isEditMode, description]);

  const handleChange = useCallback((field: keyof Omit<MeetingOccurrence, 'title'>, value: any) => {
    setOccurrenceData(prevData => ({ ...prevData, [field]: value }));

    if (isEditMode && initialData?.slug) {
      onEdit(initialData.slug, { [field]: value });
    }
  }, [isEditMode, initialData, onEdit]);

  const handleTitleBlur = useCallback(() => {
    if (initialData?.slug && title !== initialTitleRef.current) {
      onEdit(initialData.slug, { title });
      initialTitleRef.current = title;
    }
  }, [initialData, title, onEdit]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleTopicClick = (topic: { id: string; title: string }) => {
    const exists = accordionItems.some(item => item.id === topic.id);
    if (exists) return;

    setAccordionItems(prevItems => [
      ...prevItems,
      {
        id: topic.id,
        title: topic.title,
        content: '',
        descriptionPlaceholder: `Add notes for ${topic.title}`,
      },
    ]);
  };

  const handleUpdateItemContent = (id: string, content: string) => {
    setAccordionItems(prevItems =>
      prevItems.map(item => (item.id === id ? { ...item, content } : item))
    );
  };

  const handleAddItem = () => {
    setAccordionItems(prevItems => [
      ...prevItems,
      {
        id: `new-${Date.now()}`,
        title: 'New Topic',
        content: '',
        descriptionPlaceholder: 'Add notes for the new topic',
      },
    ]);
  };

  const handleRemoveItem = (id: string) => {
    setAccordionItems(prevItems => prevItems.filter(item => item.id !== id));
    
    // If the topic had notes before, add it to deleted topics
    if (initialTopicNotesRef.current[id]) {
      setDeletedTopicIds(prev => [...prev, id]);
    }
  };

  const handleDescriptionChange = useCallback((newDescription: string) => {
    if (!initialData) {
      // For new occurrences, use draft functionality
      handleDraftChange(newDescription);
    }
    setDescription(newDescription);
  }, [initialData, handleDraftChange]);

  const handleSave = async () => {
    if (!title.trim()) {
      alert('Please provide a title');
      return;
    }

    // Prepare topic notes operations
    const operations: TopicNoteOperation[] = [];

    // Handle updates and creates
    accordionItems.forEach(item => {
      const initialContent = initialTopicNotesRef.current[item.id];
      const operation: TopicNoteOperation = {
        operation: initialContent ? 'update' : 'create',
        topic_id: item.id,
        notes: item.content
      };
      operations.push(operation);
    });

    // Handle deletes
    deletedTopicIds.forEach(id => {
      operations.push({
        operation: 'delete',
        topic_id: id
      });
    });

    const dataToSave = {
      ...occurrenceData,
      title,
      description,
      date: occurrenceData.date,
      topic_notes_operations: operations
    };

    try {
      const savedOccurrence = await onSave(
        dataToSave as Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc'> & {
          topic_notes_operations: TopicNoteOperation[];
        }, 
        initialData?.slug
      );

      if (!savedOccurrence || !savedOccurrence.id) {
        throw new Error('Failed to get the ID of the saved occurrence');
      }

      if (!initialData) {
        clearDraft(); // Clear draft for new occurrences
      }

      setSavedOccurrenceId(savedOccurrence.id);
      onClose();
      if (onSuccess) onSuccess('Meeting occurrence saved successfully');
    } catch (error) {
      console.error('Error saving Meeting Occurrence:', error);
      if (onError) onError('Failed to save Meeting Occurrence.');
      alert('Failed to save Meeting Occurrence. Please try again.');
    }
  };

  if (!isOpen) return null;

  const getStateLabels = (states: string[], options: { value: string; label: string }[]) => {
    return states.map(state => {
      const option = options.find(opt => opt.value === state);
      return option ? option.label : state;
    });
  };

  const lifecycleStates = ['SCHEDULED', 'IN_PROGRESS', 'COMPLETED'];
  const friendlyLifecycleStates = getStateLabels(lifecycleStates, meetingOccurrenceStateOptions);

  return (
    <MeetingTypeContainer
      render={({ data: meetingTypes, loading, error }) => {
        if (loading) return <div>Loading Meeting Types...</div>;
        if (error) return <div>{error}</div>;

        const meetingTypeOptions: SelectOption[] = meetingTypes.map((type) => ({
          label: type.name, 
          value: type.id, 
          slug: type.slug,
        }));

        return (
          <ModalContent
            title={title}
            description={description}
            onCancel={handleClose}
            onCreate={handleSave}
            onTitleChange={setTitle}
            onTitleBlur={handleTitleBlur}
            onDescriptionChange={handleDescriptionChange}
            showWarning={!initialData && hasUnsavedDraft}
            warningTooltip={!initialData && hasUnsavedDraft ? 'You have an unsaved draft' : undefined}
            actionButtonLabel={isEditMode ? 'Save Changes' : 'Create Meeting Occurrence'}
            lifecycleStates={friendlyLifecycleStates}
            currentState={getStateLabels([occurrenceData?.state || ''], meetingOccurrenceStateOptions)[0]}
            showLifecycle={true}
            showFooterButtons={true}
            showSidePanel={true}
            sidePanelContent={
              <MeetingOccurrenceSidePanel 
                meetingTypeId={occurrenceData.meeting_type?.id || ''} 
                currentMeetingOccurrenceId={savedOccurrenceId}
                onTopicClick={handleTopicClick} 
              />
            }
            selects={[  
              {
                component: MeetingTypeSelect,
                props: {
                  value: occurrenceData.meeting_type?.id || null,
                  onChange: (selectedType: MeetingType) => {
                    handleChange('meeting_type', selectedType);
                    setMeetingTypeSlug(selectedType?.slug || '');
                  },
                  isDisabled: isEditMode
                },
              },
              {
                component: SelectSingle,
                props: {
                  options: meetingOccurrenceStateOptions,
                  placeholder: 'Select State',
                  defaultIcon: <Icon name="Flag" size="sm" />,
                  defaultValue: occurrenceData.state,
                  onSelect: (value: string) =>
                    handleChange('state', value as MeetingOccurrence['state']),
                },
              },
              {
                component: () => (
                  <Datepicker
                    defaultValue={occurrenceData.date || ''}
                    onChange={(newDate: string) => handleChange('date', newDate)}
                    placeholder="Select Date"
                  />
                ),
                props: {},
              },
              {
                component: PersonSelectMulti,
                props: {
                  value: occurrenceData.attendees?.map((person) => person.id) || [],
                  onChange: (newAttendees: Person[]) => handleChange('attendees', newAttendees),
                  enableSearch: true,
                  placeholder: 'Select Attendees',
                },
              },
              {
                component: TagSelect,
                props: {
                  value: occurrenceData.tags?.map((tag) => tag.id) || [],
                  onChange: (newTags: Tag[]) => handleChange('tags', newTags),
                  enableSearch: true,
                  placeholder: 'Select Tags',
                },
              },
            ]}
          >
            <SeparatorHorizontal className="my-6" />
            <h2 className="text-xl font-semibold mb-4">Topics</h2>
            <AccordionList
              items={accordionItems}
              onUpdateItemContent={handleUpdateItemContent}
              onAddItem={handleAddItem}
              onRemoveItem={handleRemoveItem}
            />
            <div className="mb-20" />
          </ModalContent>
        );
      }}
    />
  );
};

export default MeetingOccurrenceModal;
