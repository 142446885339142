import { MeetingType, MeetingOccurrence, SmartSuggestion, MeetingTopicNote, MeetingTopicNoteCreate, MeetingTopicNoteUpdate, TopicNoteOperation, MeetingOccurrencePayload } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

// ========================= Meeting Types ========================= //

// Fetch all Meeting Types
export const fetchMeetingTypes = async (navigate: (path: string) => void): Promise<MeetingType[]> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Meeting Types');

  return response.json();
};

// Fetch a single Meeting Type by slug
export const fetchMeetingType = async (slug: string, navigate: (path: string) => void): Promise<MeetingType> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  // Validate slug
  if (!slug) throw new Error('Slug is required to fetch a Meeting Type.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/${slug}/`, {}, navigate);

  if (!response.ok) throw new Error(`Failed to fetch Meeting Type with slug ${slug}`);

  return response.json();
};


// Create a new Meeting Type
export const createMeetingType = async (
  meetingTypeData: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>,
  navigate: (path: string) => void
): Promise<MeetingType> => {
  const response = await fetchWithAuth(`${API_URL}meeting-types/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...meetingTypeData,
      regular_attendees_ids: meetingTypeData.regular_attendees.map((attendee) => attendee.id),
      tags_ids: meetingTypeData.tags.map((tag) => tag.id),
      backlog_topics_ids: meetingTypeData.backlog_topics.map((topic) => topic.id),
    }),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Meeting Type');

  return response.json();
};

// Edit an existing Meeting Type
export const editMeetingType = async (
  slug: string,
  updatedMeetingType: Partial<MeetingType>,
  navigate: (path: string) => void
): Promise<MeetingType> => {
  const response = await fetchWithAuth(`${API_URL}meeting-types/${slug}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...updatedMeetingType,
      regular_attendees_ids: updatedMeetingType.regular_attendees?.map((attendee) => attendee.id),
      tags_ids: updatedMeetingType.tags?.map((tag) => tag.id),
      backlog_topics_ids: updatedMeetingType.backlog_topics?.map((topic) => topic.id),
    }),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Meeting Type with slug ${slug}`);

  return response.json();
};

// Delete an existing Meeting Type
export const deleteMeetingType = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-types/${slug}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Meeting Type with slug ${slug}`);
};


// ========================= Meeting Occurrences ========================= //

// Fetch all Meeting Occurrences
export const fetchMeetingOccurrences = async (navigate: (path: string) => void): Promise<MeetingOccurrence[]> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Meeting Occurrences');

  return response.json();
};

// Fetch a single Meeting Occurrence by slug
export const fetchMeetingOccurrence = async (slug: string, navigate: (path: string) => void): Promise<MeetingOccurrence> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/${slug}/`, {}, navigate);
  if (!response.ok) throw new Error(`Failed to fetch Meeting Occurrence with slug ${slug}`);

  return response.json();
};

// Create a new Meeting Occurrence
export const createMeetingOccurrence = async (
  occurrenceData: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'> & {
    topic_notes_operations?: TopicNoteOperation[];
  },
  navigate: (path: string) => void
): Promise<MeetingOccurrence> => {
  const payload: MeetingOccurrencePayload = {
    title: occurrenceData.title,
    description: occurrenceData.description,
    date: occurrenceData.date,
    state: occurrenceData.state,
    raw_notes: occurrenceData.raw_notes,
    summary: occurrenceData.summary,
    attendees_ids: occurrenceData.attendees.map((attendee) => attendee.id),
    tags_ids: occurrenceData.tags.map((tag) => tag.id),
    tasks_ids: occurrenceData.tasks?.map((task) => task.id) || [],
    projects_ids: occurrenceData.projects?.map((project) => project.id) || [],
    meeting_type_id: occurrenceData.meeting_type?.id || null,
    topic_notes_operations: occurrenceData.topic_notes_operations
  };

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }, navigate);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to create Meeting Occurrence: ${JSON.stringify(errorData)}`);
  }

  const createdOccurrence = await response.json();
  return {
    ...createdOccurrence,
    attendees: occurrenceData.attendees,
    tags: occurrenceData.tags,
    tasks: occurrenceData.tasks || [],
    projects: occurrenceData.projects || [],
  };
};

// Edit an existing Meeting Occurrence
export const editMeetingOccurrence = async (
  slug: string,
  updatedOccurrence: Partial<MeetingOccurrence> & {
    topic_notes_operations?: TopicNoteOperation[];
  },
  navigate: (path: string) => void
): Promise<MeetingOccurrence> => {
  const payload: Partial<MeetingOccurrencePayload> = {
    title: updatedOccurrence.title,
    description: updatedOccurrence.description,
    date: updatedOccurrence.date,
    state: updatedOccurrence.state,
    raw_notes: updatedOccurrence.raw_notes,
    summary: updatedOccurrence.summary,
    // Convert relationships to *_ids
    attendees_ids: updatedOccurrence.attendees?.map(attendee => attendee.id),
    tags_ids: updatedOccurrence.tags?.map(tag => tag.id),
    tasks_ids: updatedOccurrence.tasks?.map(task => task.id),
    projects_ids: updatedOccurrence.projects?.map(project => project.id),
    // Safely handle meeting_type_id
    meeting_type_id: updatedOccurrence.meeting_type ? 
      (typeof updatedOccurrence.meeting_type === 'object' ? 
        updatedOccurrence.meeting_type.id : 
        updatedOccurrence.meeting_type
      ) : null,
    // Include topic notes operations
    topic_notes_operations: updatedOccurrence.topic_notes_operations
  };

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/${slug}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Meeting Occurrence with slug ${slug}`);

  return response.json();
};

// Delete an existing Meeting Occurrence
export const deleteMeetingOccurrence = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}meeting-occurrences/${slug}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Meeting Occurrence with slug ${slug}`);
};



// ========================= Meeting Topic Notes ========================= //

// Fetch all Meeting Topic Notes for a Meeting Occurrence
export const fetchMeetingTopicNotes = async (meetingOccurrenceId: string, navigate: (path: string) => void): Promise<MeetingTopicNote[]> => {
  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/?meeting_id=${meetingOccurrenceId}`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Meeting Topic Notes');

  return response.json();
};

// Create a new Meeting Topic Note
export const createMeetingTopicNote = async (
  noteData: MeetingTopicNoteCreate & { meeting_occurrence_id: string },
  navigate: (path: string) => void
): Promise<MeetingTopicNote> => {
  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(noteData),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Meeting Topic Note');

  return response.json();
};

// Edit an existing Meeting Topic Note
export const editMeetingTopicNote = async (
  id: string,
  updatedNote: MeetingTopicNoteUpdate & { meeting_occurrence_id?: string },
  navigate: (path: string) => void
): Promise<MeetingTopicNote> => {
  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/${id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedNote),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Meeting Topic Note with ID ${id}`);

  return response.json();
};

// Delete an existing Meeting Topic Note
export const deleteMeetingTopicNote = async (id: string, navigate: (path: string) => void): Promise<void> => {
  const response = await fetchWithAuth(`${API_URL}meeting-topic-notes/${id}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Meeting Topic Note with ID ${id}`);
};


// ========================= Smart Suggestions ========================= //

// Fetch all Smart Suggestions
export const fetchSmartSuggestions = async (meetingOccurrenceId: string, navigate: (path: string) => void): Promise<SmartSuggestion[]> => {
  const response = await fetchWithAuth(`${API_URL}meetings/smart-suggestions/?meeting_occurrence_id=${meetingOccurrenceId}`, {}, navigate);
  if (!response.ok) throw new Error('Failed to fetch Smart Suggestions');

  return response.json();
};

// Create a new Smart Suggestion
export const createSmartSuggestion = async (
  suggestionData: Omit<SmartSuggestion, 'id' | 'created_at'>,
  navigate: (path: string) => void
): Promise<SmartSuggestion> => {
  const response = await fetchWithAuth(`${API_URL}smart-suggestions/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(suggestionData),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Smart Suggestion');

  return response.json();
};

// Edit an existing Smart Suggestion
export const editSmartSuggestion = async (
  id: string,
  updatedSuggestion: Partial<SmartSuggestion>,
  navigate: (path: string) => void
): Promise<SmartSuggestion> => {
  const response = await fetchWithAuth(`${API_URL}meetings/smart-suggestions/${id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedSuggestion),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Smart Suggestion with ID ${id}`);

  return response.json();
};

// Delete an existing Smart Suggestion
export const deleteSmartSuggestion = async (id: string, navigate: (path: string) => void): Promise<void> => {
  const response = await fetchWithAuth(`${API_URL}meetings/smart-suggestions/${id}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Smart Suggestion with ID ${id}`);
};
