import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ModalContent, SelectSingle } from '../ui'; 
import { MeetingType, RecurrenceType, DurationType } from '../../api/meeting/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import PersonSelectMulti from '../person/PersonSelectMulti';
import TagSelect from '../tags/TagSelect';
import { Topic } from '../../api/topic/types';
import MeetingTypeSidePanel from './MeetingTypeSidePanel';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';
import { recurrenceOptions, durationOptions } from '../../utils/entitiesOptions';

interface MeetingTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (meetingTypeData: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>, slug?: string) => void;
  onEdit: (slug: string, updatedMeetingType: Partial<MeetingType>) => void;
  onDelete?: (slug: string) => void;
  onSuccess?: (message: string) => void;
  initialData?: Omit<MeetingType, 'created_at' | 'updated_at'>;
}

export const MeetingTypeModal: React.FC<MeetingTypeModalProps> = ({ 
  isOpen, 
  onClose, 
  onSave, 
  onEdit,
  onDelete,
  onSuccess,
  initialData 
}) => {
  const [meetingTypeData, setMeetingTypeData] = useState<Partial<MeetingType>>({
    name: '',
    description: '',
    recurrence: 'WEEKLY',
    duration: '1h',
    regular_attendees: [],
    tags: [],
    backlog_topics: [],
    company: 'default-company-id',
  });
  const [description, setDescription] = useState('');
  const isInitialMount = useRef(true);
  const initialNameRef = useRef('');
  const initialDescriptionRef = useRef('');
  const editFunctionRef = useRef<((slug: string, updatedMeetingType: Partial<MeetingType>) => void) | null>(null);
  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);

  useEffect(() => {
    if (isInitialMount.current) {
      if (initialData) {
        setMeetingTypeData(initialData);
        setDescription(initialData.description || '');
        initialNameRef.current = initialData.name || '';
        initialDescriptionRef.current = initialData.description || '';
        setSelectedTopics(initialData.backlog_topics || []);
        console.log('Initializing with topics:', initialData.backlog_topics);
      }
      isInitialMount.current = false;
    } else if (initialData) {
      setMeetingTypeData(prevData => ({
        ...prevData,
        company: initialData.company,
      }));
    }
  }, [initialData, isOpen]);

  // Save on refresh/unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [initialData?.slug, description]);

  // 30m autosave
  useEffect(() => {
    if (!initialData?.slug || !editFunctionRef.current || description === initialDescriptionRef.current) return;

    const autoSaveTimer = setTimeout(() => {
      if (initialData?.slug && editFunctionRef.current && description !== initialDescriptionRef.current) {
        editFunctionRef.current(initialData.slug, { description });
        initialDescriptionRef.current = description;
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(autoSaveTimer);
  }, [description, initialData?.slug]);

  const handleChange = useCallback((field: keyof MeetingType, value: any) => {
    console.log('handleChange called for field:', field, 'value:', value);
    setMeetingTypeData(prevData => ({ ...prevData, [field]: value }));

    if (initialData?.slug && field !== 'description' && field !== 'name') {
      onEdit(initialData.slug, { [field]: value });
    }
  }, [initialData, onEdit]);

  const handleClose = useCallback(() => {
    if (initialData?.slug && description !== initialDescriptionRef.current) {
      onEdit(initialData.slug, { description });
      initialDescriptionRef.current = description;
    }
    onClose();
  }, [initialData, description, onEdit, onClose]);

  const handleNameBlur = useCallback(() => {
    if (initialData?.slug && meetingTypeData.name !== initialNameRef.current) {
      onEdit(initialData.slug, { name: meetingTypeData.name });
      initialNameRef.current = meetingTypeData.name || '';
    }
  }, [initialData, meetingTypeData.name, onEdit]);

  const handleSave = useCallback(() => {
    if (!meetingTypeData.name?.trim()) {
      return alert('Please provide a name');
    }

    console.log('Saving with topics:', selectedTopics);
    const dataToSave = {
      ...meetingTypeData,
      description,
      backlog_topics: selectedTopics,
    };

    onSave(dataToSave as Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>, initialData?.slug);
    initialDescriptionRef.current = description;
    onClose();
  }, [meetingTypeData, description, selectedTopics, initialData, onSave, onClose]);

  const handleDelete = useCallback(() => {
    if (initialData?.slug && onDelete) {
      onDelete(initialData.slug);
      onSuccess?.('Meeting type deleted successfully');
      onClose();
    }
  }, [initialData, onDelete, onSuccess, onClose]);

  const handleTopicSelect = useCallback((topic: Topic) => {
    console.log('Selecting topic:', topic);
    const updatedTopics = [...selectedTopics, topic];
    setSelectedTopics(updatedTopics);
    handleChange('backlog_topics', updatedTopics);
  }, [selectedTopics, handleChange]);

  const handleTopicDeselect = useCallback((topicId: string) => {
    console.log('Deselecting topic:', topicId);
    const updatedTopics = selectedTopics.filter(topic => topic.id !== topicId);
    setSelectedTopics(updatedTopics);
    handleChange('backlog_topics', updatedTopics);
  }, [selectedTopics, handleChange]);

  if (!isOpen) return null;

  // Keep track of edit function
  editFunctionRef.current = onEdit;

  return (
    <ModalContent
      title={meetingTypeData.name || ''}
      description={description}
      onCancel={handleClose}
      onCreate={handleSave}
      onDelete={initialData ? handleDelete : undefined}
      onTitleChange={(newName) => setMeetingTypeData(prevData => ({ ...prevData, name: newName }))}
      onTitleBlur={handleNameBlur}
      onDescriptionChange={setDescription}
      descriptionPlaceholder='Add a template...'
      actionButtonLabel={initialData ? 'Save Changes' : 'Create Meeting Type'}
      showFooterButtons={true}
      activityFeed={initialData?.id ? <LogEntryActivityFeed objectId={initialData.id} objectType='meeting_type' /> : null}
      showActivityFeed={true}
      selects={[
        {
          component: SelectSingle,
          props: {
            options: recurrenceOptions,
            placeholder: 'Select Recurrence',
            defaultValue: meetingTypeData.recurrence,
            onSelect: (value: string) => handleChange('recurrence', value as RecurrenceType),
          },
        },
        {
          component: SelectSingle,
          props: {
            options: durationOptions,
            placeholder: 'Select Duration',
            defaultValue: meetingTypeData.duration,
            onSelect: (value: string) => handleChange('duration', value as DurationType),
          },
        },
        {
          component: PersonSelectMulti,
          props: {
            value: meetingTypeData.regular_attendees?.map((person) => person.id) || [],
            onChange: (newAttendees: Person[]) => handleChange('regular_attendees', newAttendees),
            enableSearch: true,
            placeholder: 'Select Regular Attendees',
          },
        },
        {
          component: TagSelect,
          props: {
            value: meetingTypeData.tags?.map((tag) => tag.id) || [],
            onChange: (newTags: Tag[]) => handleChange('tags', newTags),
            enableSearch: true,
            placeholder: 'Select Tags',
          },
        },
      ]}
      showSidePanel={!!initialData}
      sidePanelContent={
        initialData && (
          <MeetingTypeSidePanel
            meetingTypeId={initialData?.id}
            onTopicSelect={handleTopicSelect}
            onTopicDeselect={handleTopicDeselect}
            selectedTopics={selectedTopics}
          />
        )
      }
    />
  );
};

export default MeetingTypeModal;