import { StateTransition } from './types';
import { fetchWithAuth } from '../fetchWithAuth';  // Import fetchWithAuth method

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all State Transitions for a specific model and object
export const fetchStateTransitions = async (
  model: string, 
  objectId: string, 
  navigate: (path: string) => void
): Promise<StateTransition[]> => {
  const response = await fetchWithAuth(
    `${API_URL}transitions/?model=${model}&object_id=${objectId}`, 
    {}, 
    navigate
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch state transitions for ${model} with ID ${objectId}`);
  }

  return response.json();
};