import { clearTokens } from '../utils/auth';

export const fetchWithAuth = async (
    url: string, 
    options: RequestInit = {}, 
    navigate: (path: string) => void
): Promise<Response> => {
    try {
        // Fetch CSRF token from the DOM (cast to HTMLInputElement)
        const csrfToken = (document.querySelector('[name=csrfmiddlewaretoken]') as HTMLInputElement)?.value || getCookie('csrftoken') || '';

        // Merge user options with default headers and CSRF token
        const response = await fetch(url, {
            ...options,
            credentials: 'include', // Important for sending cookies
            headers: {
                ...options.headers,
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,  // Include CSRF token here
            },
        });

        // Check if session is expired/invalid
        if (response.status === 401) {
            clearTokens();  // Clear any stored IDs
            navigate('/login');  // Redirect user to login page
            throw new Error('Session expired. Redirecting to login.');
        }

        return response;  // Keep the same return type
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;  // Rethrow error for any other unhandled cases
    }
};

// Utility function to get the CSRF token from cookies (if stored there)
function getCookie(name: string): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
}

// Add CSRF token to headers
function getCsrfToken() {
    return document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
}

// In your fetch configuration
const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCsrfToken() || '',
    // ... other headers
};