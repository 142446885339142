const PERSON_ID_KEY = 'person_id';
const COMPANY_ID_KEY = 'company_id';

interface StorageData {
    personId: string | null;
    companyId: string | null;
}

// Store person ID and company ID
export const setTokens = (personId?: string, companyId?: string): void => {
    try {
        if (personId) localStorage.setItem(PERSON_ID_KEY, personId);
        if (companyId) localStorage.setItem(COMPANY_ID_KEY, companyId);
    } catch (error) {
        console.error('Failed to set storage tokens:', error);
    }
};

// Clear user data from localStorage
export const clearTokens = (): void => {
    try {
        localStorage.removeItem(PERSON_ID_KEY);
        localStorage.removeItem(COMPANY_ID_KEY);
    } catch (error) {
        console.error('Failed to clear storage tokens:', error);
    }
};

// Get the stored data
export const getStorageData = (): StorageData => {
    try {
        return {
            personId: localStorage.getItem(PERSON_ID_KEY),
            companyId: localStorage.getItem(COMPANY_ID_KEY)
        };
    } catch (error) {
        console.error('Failed to get storage data:', error);
        return { personId: null, companyId: null };
    }
};

// Check if the user is authenticated
export const isAuthenticated = (): boolean => {
    const { personId, companyId } = getStorageData();
    return Boolean(personId && companyId);
};

// Get the person ID
export const getPersonId = (): string | null => getStorageData().personId;

// Get the company ID
export const getCompanyId = (): string | null => getStorageData().companyId;