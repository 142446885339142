import { Goal } from './types';
import { LogEntry } from '../logEntry/types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export interface FetchGoalsParams {
 personId?: string;
 teamId?: string;
}

export const fetchGoals = async (
 params: FetchGoalsParams,
 navigate: (path: string) => void
): Promise<Goal[]> => {
 const company_id = getCompanyId();
 if (!company_id) throw new Error('Company ID not found.');

 let url = `${API_URL}goals/`;
 const queryParams = [];
 if (params.teamId) queryParams.push(`team_id=${params.teamId}`);
 if (params.personId) queryParams.push(`person_id=${params.personId}`);
 
 if (queryParams.length > 0) {
   url += `?${queryParams.join('&')}`;
 }

 const response = await fetchWithAuth(url, {}, navigate);
 if (!response.ok) throw new Error('Failed to fetch goals');
 return response.json();
};

export const fetchGoal = async (slug: string, navigate: (path: string) => void): Promise<Goal> => {
 const company_id = getCompanyId();
 if (!company_id) throw new Error('Company ID not found.');

 const response = await fetchWithAuth(`${API_URL}goals/${slug}/`, {}, navigate);
 if (!response.ok) throw new Error(`Failed to fetch goal with slug ${slug}`);
 return response.json();
};

export const createGoal = async (
 goalData: Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>,
 navigate: (path: string) => void
): Promise<Goal> => {
 const { company, ...data } = goalData;

 const response = await fetchWithAuth(`${API_URL}goals/`, {
   method: 'POST',
   body: JSON.stringify({
     ...data,
     participant_ids: data.participants.map(p => p.id),
     project_ids: data.projects.map(p => p.id),
     topic_ids: data.topics.map(t => t.id),
     tag_ids: data.tags.map(t => t.id),
     team_id: data.team?.id,
     owner_id: data.owner?.id
   }),
 }, navigate);

 if (!response.ok) throw new Error('Failed to create goal');
 return response.json();
};

export const editGoal = async (
 slug: string,
 updatedGoal: Partial<Goal>,
 navigate: (path: string) => void
): Promise<Goal> => {
 const { company, ...data } = updatedGoal;

 const response = await fetchWithAuth(`${API_URL}goals/${slug}/`, {
   method: 'PATCH',
   body: JSON.stringify({
     ...data,
     participant_ids: data.participants?.map(p => p.id),
     project_ids: data.projects?.map(p => p.id),
     topic_ids: data.topics?.map(t => t.id),
     tag_ids: data.tags?.map(t => t.id),
     team_id: data.team?.id,
     owner_id: data.owner?.id
   }),
 }, navigate);

 if (!response.ok) throw new Error(`Failed to edit goal with slug ${slug}`);
 return response.json();
};

export const deleteGoal = async (slug: string, navigate: (path: string) => void): Promise<void> => {
 const company_id = getCompanyId();
 if (!company_id) throw new Error('Company ID not found.');

 const response = await fetchWithAuth(`${API_URL}goals/${slug}/`, {
   method: 'DELETE',
 }, navigate);

 if (!response.ok) throw new Error(`Failed to delete goal with slug ${slug}`);
};