import { Topic } from './types';
import { LogEntry } from '../logEntry/types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Updated FetchTopicsParams
export interface FetchTopicsParams {
  meetingTypeId?: string;
  personId?: string;
}

// Fetch all topics
export const fetchTopics = async (
  { meetingTypeId, personId }: FetchTopicsParams,
  navigate: (path: string) => void
): Promise<Topic[]> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  // Build the URL based on the provided parameters
  let url = `${API_URL}topics/`;
  const queryParams = [];
  if (meetingTypeId) {
    queryParams.push(`meeting_type_id=${meetingTypeId}`);
  }
  if (personId) {
    queryParams.push(`person_id=${personId}`);
  }

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  const response = await fetchWithAuth(url, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch topics`);
  }
  return response.json();
};

// Fetch a single topic by slug
export const fetchTopic = async (slug: string, navigate: (path: string) => void): Promise<Topic> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}topics/${slug}/`, {}, navigate);
  if (!response.ok) {
    throw new Error(`Failed to fetch topic with slug ${slug}`);
  }
  return response.json();
};

// Create a new topic
export const createTopic = async (
  topicData: Omit<Topic, 'id' | 'created_at' | 'updated_at' | 'slug'>,
  navigate: (path: string) => void
): Promise<Topic> => {
  const company_id = getCompanyId();

  const response = await fetchWithAuth(`${API_URL}topics/`, {
    method: 'POST',
    body: JSON.stringify({
      ...topicData,
      company: company_id,
      persons_ids: topicData.persons.map(person => person.id),
      tags_ids: topicData.tags.map(tag => tag.id),
      meeting_types_ids: topicData.meeting_types?.map(mt => mt.id),
    }),
  }, navigate);
  if (!response.ok) {
    throw new Error('Failed to create topic');
  }
  return response.json();
};

// Edit an existing topic (using PATCH)
export const editTopic = async (
  slug: string,
  updatedTopic: Partial<Topic>,
  navigate: (path: string) => void
): Promise<Topic> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}topics/${slug}/`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...updatedTopic,
      company: company_id,
      persons_ids: updatedTopic.persons?.map(person => person.id),
      tags_ids: updatedTopic.tags?.map(tag => tag.id),
      meeting_types_ids: updatedTopic.meeting_types?.map(mt => mt.id),
    }),
  }, navigate);
  if (!response.ok) {
    throw new Error(`Failed to edit topic with slug ${slug}`);
  }
  return response.json();
};

// Delete an existing topic
export const deleteTopic = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}topics/${slug}/`, {
    method: 'DELETE',
  }, navigate);
  if (!response.ok) {
    throw new Error(`Failed to delete topic with slug ${slug}`);
  }
};

// Create a log entry for a specific topic
export const createTopicLogEntry = async (
  topicId: string,
  logEntryData: Omit<LogEntry, 'id' | 'created_at' | 'created_by'>,
  navigate: (path: string) => void
): Promise<LogEntry> => {
  const response = await fetchWithAuth(`${API_URL}topics/${topicId}/add_log_entry/`, {
    method: 'POST',
    body: JSON.stringify(logEntryData),
  }, navigate);
  if (!response.ok) {
    throw new Error('Failed to create log entry');
  }
  return response.json();
};