import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { validateMagicLink } from '../../services/authService';
import { useUser } from '../../contexts/UserContext';
import { setTokens } from '../../utils/auth';

const MagicLinkLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setSession } = useUser();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token) {
            validateToken(token);
        } else {
            console.error('No token found in the URL');
            navigate('/login');
        }
    }, [location, navigate]);

    const validateToken = async (token: string) => {
        try {
            const response = await validateMagicLink(token);

            // Store person_id and company_id in localStorage and update session context
            setTokens(response.person_id, response.company_id);
            setSession(response.person_id, response.company_id);

            navigate('/tasks/board'); // Redirect to dashboard
        } catch (error: any) {
            console.error('Magic link validation failed:', error);
            const errorMessage = error.response?.data?.error || 'Invalid or expired link';
            // Show error message to user
            navigate('/login', { 
                state: { error: errorMessage } 
            });
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
            <p className="text-lg font-medium text-gray-700">
                Validating your magic link...
            </p>
        </div>
    );
};

export default MagicLinkLogin;
