import React from 'react';
import { useTopics, useCreateTopic, useEditTopic, useDeleteTopic, useCreateLogEntry } from '../api/topic/queries';
import { Topic } from '../api/topic/types';
import { LogEntry } from '../api/logEntry/types';
import { useQueryClient } from 'react-query';
import { ALERTS_QUERY_KEY } from '../api/alert/queries';

interface TopicContainerProps {
  meetingTypeId?: string;
  personId?: string;
  render: (props: {
    data: Topic[];
    loading: boolean;
    error: string | null;
    onCreate: (newTopic: Omit<Topic, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void;
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void;
    onDelete: (slug: string) => void;
    onCreateLogEntry: (topicId: string, newLogEntry: Omit<LogEntry, 'id' | 'created_at' | 'created_by'>) => void;
  }) => JSX.Element;
}

const TopicContainer: React.FC<TopicContainerProps> = ({ meetingTypeId, personId, render }) => {
  const params = { meetingTypeId, personId };
  const queryClient = useQueryClient();

  const queryKey = ['topics', params];

  const { data: topics = [], isLoading, error } = useTopics(params);
  const createTopicMutation = useCreateTopic(params);
  const editTopicMutation = useEditTopic(params);
  const deleteTopicMutation = useDeleteTopic(params);
  const createLogEntryMutation = useCreateLogEntry(params);

  const handleCreate = async (newTopic: Omit<Topic, 'id' | 'created_at' | 'updated_at' | 'slug'>): Promise<Topic> => {
    const result = await createTopicMutation.mutateAsync(newTopic);

    // Directly update cache after successful mutation
    queryClient.setQueryData(queryKey, (oldTopics: Topic[] | undefined) => [
        result, // Add the new topic to the top
        ...(oldTopics || []), // Retain the rest of the topics
    ]);

    return result; // Return the newly created topic
};


  const handleEdit = (slug: string, updatedTopic: Partial<Topic>) => {
    editTopicMutation.mutate({ slug, updatedTopic }, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(ALERTS_QUERY_KEY);
      }
    });
  };

  const handleDelete = (slug: string) => {
    deleteTopicMutation.mutate(slug, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries(ALERTS_QUERY_KEY);
      }
    });
  };

  const handleCreateLogEntry = (topicId: string, newLogEntry: Omit<LogEntry, 'id' | 'created_at' | 'created_by'>) => {
    createLogEntryMutation.mutate({ topicId, logEntryData: newLogEntry }, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      }
    });
  };

  const sortedTopics = React.useMemo(() => 
    [...topics].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    ), [topics]
  );

  return render({
    data: sortedTopics,
    loading: isLoading,
    error: error ? (error as Error).message : null,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
    onCreateLogEntry: handleCreateLogEntry,
  });
};

export default TopicContainer;