import React, { useState } from 'react';
import { MeetingOccurrence } from '../../api/meeting/types';
import { PanelList, Icon, Button, Snackbar } from '../ui';
import MeetingOccurrenceContainer from '../../containers/MeetingOccurrenceContainer';
import { useQueryClient } from 'react-query';
import { MeetingOccurrenceModal } from './MeetingOccurrenceModal';

interface MeetingPastOccurrencesPanelProps {
  isOpen: boolean;
  onClose?: () => void;
  meetingTypeId?: string;
  attendeeId?: string;
  currentMeetingOccurrenceId?: string;
  onOccurrenceClick?: (occurrence: MeetingOccurrence) => void;
  title?: string;
  showAddButton?: boolean;
}

const MeetingPastOccurrencesPanel: React.FC<MeetingPastOccurrencesPanelProps> = ({
  isOpen,
  onClose,
  meetingTypeId,
  attendeeId,
  currentMeetingOccurrenceId,
  onOccurrenceClick,
  title = '',
  showAddButton = false,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [occurrenceToEdit, setOccurrenceToEdit] = useState<MeetingOccurrence | null>(null);
  const queryClient = useQueryClient();

  const openOccurrenceCreateModal = () => {
    setOccurrenceToEdit(null);
    setShowModal(true);
  };

  const openOccurrenceEditModal = (occurrence: MeetingOccurrence) => {
    setOccurrenceToEdit(occurrence);
    setShowModal(true);
  };

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
        {showAddButton && (
          <Button
            size="medium"
            variant="primary"
            label="Add New Occurrence"
            onClick={openOccurrenceCreateModal}
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        <MeetingOccurrenceContainer
            meetingTypeId={meetingTypeId}
            attendeeId={attendeeId}
            render={({ data: occurrences, loading, error, onCreate, onEdit, onDelete }) => {
              const sortedOccurrences = [...occurrences]
                .filter(occurrence => occurrence.id !== currentMeetingOccurrenceId)
                .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

              return (
              <>
                {loading && <div className="p-4">Loading occurrences...</div>}
                {error && <div className="p-4 text-red-500">Error: {error}</div>}
                {!loading && !error && sortedOccurrences.length === 0 && (
                  <div className="p-4">No occurrences found</div>
                )}

                {!loading && !error && sortedOccurrences.length > 0 && (
                  <>
                    <PanelList
                      items={sortedOccurrences}
                      loading={loading}
                      renderItem={(occurrence) => (
                        <div key={occurrence.id} className="relative p-4">
                          <div className="absolute top-2 right-2 flex space-x-1">
                            <button
                              onClick={() => openOccurrenceEditModal(occurrence)}
                              className="text-primary hover:text-primary-dark focus:outline-none"
                              title="Edit Occurrence"
                            >
                              <Icon name="Maximize" size="sm" />
                            </button>
                          </div>
                          <div className="flex flex-col space-y-1">
                            <span className="text-base font-semibold">{occurrence.title}</span>
                            <div className="flex text-xs text-gray-500 space-x-2">
                              <p>Date: {new Date(occurrence.date).toLocaleDateString()}</p>
                              {occurrence.created_by && (
                                <p>• Created by: {occurrence.created_by.name}</p>
                              )}
                            </div>
                            {occurrence.description && (
                              <div
                                className="text-sm text-gray-600"
                                dangerouslySetInnerHTML={{ __html: occurrence.summary?.replace(/\n/g, '<br />') || '' }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    />
                  </>
                )}
              </>
            );
          }}
        />

        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}
      </div>
    </div>
  );
};

export default MeetingPastOccurrencesPanel;