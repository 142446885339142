import React, { useState, useEffect } from 'react';
import { Icon, IconName } from '../base/Icon';

export type SnackbarType = 'success' | 'error' | 'warning' | 'info';

interface SnackbarProps {
  message: string;
  type?: SnackbarType;
  duration?: number;
  onClose?: () => void;
}

const iconMap: Record<SnackbarType, IconName> = {
  success: 'CheckCircle',
  error: 'XCircle',
  warning: 'AlertCircle',
  info: 'Info',
};

export const Snackbar: React.FC<SnackbarProps> = ({
  message,
  type = 'info',
  duration = 3000,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose && onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const baseClasses = 'fixed bottom-5 left-4 flex items-center p-3 rounded shadow-lg transition-all duration-300 ease-in-out font-sans z-[100]';
  const typeClasses = {
    success: 'bg-accent text-background',
    error: 'bg-secondary text-background',
    warning: 'bg-secondary text-background',
    info: 'bg-primary text-background',
  };

  return isVisible ? (
    <div
      className={`${baseClasses} ${typeClasses[type]} ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-2'}`}
      style={{ left: '210px' }} // custom left spacing, e.g., 32px
    >
      <Icon name={iconMap[type]} size="md" className="mr-2" />
      <span className="mr-8 text-sm">{message}</span>
      <button
        onClick={() => {
          setIsVisible(false);
          onClose && onClose();
        }}
        className="absolute top-1 right-1 p-1 rounded-full hover:bg-background hover:bg-opacity-20 transition-colors duration-200"
      >
        <Icon name="X" size="sm" />
      </button>
    </div>
  ) : null;
  
};