import React from 'react';
import { MeetingType } from '../../api/meeting/types';
import { Table, TableActionButtons, TableBadgeComponent } from '../ui';
import { recurrenceOptions, durationOptions, getLabelForValue } from '../../utils/entitiesOptions';

interface MeetingTypeTableProps {
  data: MeetingType[];
  onEdit: (meetingType: MeetingType) => void;
  onDelete: (id: string) => void;
  onUpdateRecurrence?: (meetingTypeId: string, newRecurrence: string) => void;
  onUpdateDuration?: (meetingTypeId: string, newDuration: string) => void;
}

const MeetingTypeTable: React.FC<MeetingTypeTableProps> = ({ 
  data, 
  onEdit, 
  onDelete,
  onUpdateRecurrence,
  onUpdateDuration
}) => {
  return (
    <Table
      data={data}
      columns={[
        {
          header: 'Name',
          accessor: 'name',
          component: (row) => (
            <div
              onClick={() => onEdit(row)}
              className="cursor-pointer hover:underline"
            >
              {row.name}
            </div>
          ),
        },
        {
          header: 'Recurrence',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.recurrence, recurrenceOptions)}
              type="recurrence"
              onUpdate={(newRecurrence) => onUpdateRecurrence?.(row.slug, newRecurrence)}
              options={recurrenceOptions}
              isEditable={!!onUpdateRecurrence}
            />
          ),
        },
        {
          header: 'Duration',
          component: (row) => (
            <TableBadgeComponent
              label={getLabelForValue(row.duration, durationOptions)}
              type="duration"
              onUpdate={(newDuration) => onUpdateDuration?.(row.slug, newDuration)}
              options={durationOptions}
              isEditable={!!onUpdateDuration}
            />
          ),
        },
        {
          header: 'Actions',
          component: (row) => (
            <TableActionButtons
              onEdit={() => onEdit(row)}
              onDelete={() => onDelete(row.slug)}
            />
          ),
        },
      ]}
    />
  );
};

export default MeetingTypeTable;
