import React, { useState } from 'react';
import { Task } from '../../api/task/types';
import { Icon, Button, Snackbar } from '../ui';
import { SidePanelItem } from '../ui/panel/SidePanelItem';
import TaskContainer from '../../containers/TaskContainer';
import { TaskModal } from '../task/TaskModal';
import { useQueryClient } from 'react-query';
import { dialStageOptions, entityStyles, taskStateOptions } from '../../utils/entitiesOptions';

interface TaskPanelProps {
  isOpen: boolean;
  onClose?: () => void;
  topicId?: string;
  projectId?: string;
  meetingOccurrenceId?: string;
  meetingTypeId?: string;
  personId?: string;
  title?: string;
  showAddTaskButton?: boolean;
}

const TaskPanel: React.FC<TaskPanelProps> = ({
  isOpen,
  onClose,
  topicId,
  projectId,
  meetingOccurrenceId,
  meetingTypeId,
  personId,
  title = '',
  showAddTaskButton = true,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
  const queryClient = useQueryClient();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');

  const openTaskCreateModal = () => {
    setTaskToEdit(null);
    setShowModal(true);
  };

  const handleTaskClick = (task: Task) => {
    setTaskToEdit(task);
    setShowModal(true);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-lg font-semibold">{title}</h2>
        {showAddTaskButton && (
          <Button
            size="medium"
            variant="primary"
            label="Add New Task"
            onClick={openTaskCreateModal}
          />
        )}
      </div>

      <div className="flex-grow overflow-y-auto p-6">
        <TaskContainer
          topicId={topicId}
          projectId={projectId}
          meetingOccurrenceId={meetingOccurrenceId}
          meetingTypeId={meetingTypeId}
          personId={personId}
          render={({ data: tasksFromContainer, loading, error }) => {
            const sortedTasks = [...tasksFromContainer].sort((a, b) =>
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            return (
              <>
                {loading && (
                  <div className="flex items-center justify-center py-4">
                    <Icon name="Loader2" size="sm" className="animate-spin" />
                  </div>
                )}
                {error && <div className="p-4 text-red-500">Error: {error}</div>}
                {!loading && !error && sortedTasks.length === 0 && (
                  <div className="p-4 text-gray-500 text-sm">No tasks associated</div>
                )}

                {!loading && !error && sortedTasks.length > 0 && (
                  <div className="space-y-3">
                    {sortedTasks.map((task) => {
                      const stateInfo = entityStyles.TASK.getStateInfo(task.state);
                      
                      return (
                        <SidePanelItem
                          key={task.id}
                          title={task.title}
                          icon={entityStyles.TASK.icon}
                          prefix={entityStyles.TASK.prefix}
                          metadata={{
                            state: taskStateOptions.find(option => option.value === task.state)?.label,
                            dial_stage: dialStageOptions.find(option => option.value === task.dial_stage)?.label,
                            start_date: task.start_date,
                            due_date: task.due_date
                          }}
                          onClick={() => handleTaskClick(task)}
                          borderColor="border-l-blue-500"
                        >
                          {(task.start_date || task.due_date) && (
                            <div className="flex items-center gap-2 text-xs text-gray-400 mt-1">
                              {task.start_date && (
                                <div className="flex items-center gap-1">
                                  <Icon name="Calendar" size="sm" />
                                  <span>Start: {task.start_date}</span>
                                </div>
                              )}
                              {task.due_date && (
                                <div className="flex items-center gap-1">
                                  <Icon name="Clock" size="sm" />
                                  <span>Due: {task.due_date}</span>
                                </div>
                              )}
                            </div>
                          )}
                        </SidePanelItem>
                      );
                    })}
                  </div>
                )}

                {showModal && (
                  <TaskModal
                    isOpen={showModal}
                    initialData={taskToEdit || undefined}
                    onClose={() => setShowModal(false)}
                    onSuccess={(message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('success');
                      setShowSnackbar(true);
                      setShowModal(false);
                      queryClient.invalidateQueries([
                        'tasks',
                        { topicId, projectId, meetingOccurrenceId, meetingTypeId, personId },
                      ]);
                    }}
                    onError={(message) => {
                      setSnackbarMessage(message);
                      setSnackbarType('error');
                      setShowSnackbar(true);
                    }}
                    topicId={topicId}
                    projectId={projectId}
                    meetingOccurrenceId={meetingOccurrenceId}
                    meetingTypeId={meetingTypeId}
                    personId={personId}
                    parentModalViewMode='fullscreen'
                  />
                )}
              </>
            );
          }}
        />

        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}
      </div>
    </div>
  );
};

export default TaskPanel;