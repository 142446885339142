import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'lucide-react';

type DatepickerSize = 'small' | 'medium' | 'large';

interface DatepickerProps {
  isDisabled?: boolean;
  onChange?: (date: string) => void;
  value?: string;
  defaultValue?: string;
  loading?: boolean;
  error?: string;
  placeholder: string;
  size?: DatepickerSize;  // Add size prop to control padding and font size
}

export const Datepicker: React.FC<DatepickerProps> = ({
  isDisabled = false,
  onChange,
  value,
  defaultValue = '',
  loading = false,
  error,
  placeholder,
  size = 'medium',  // Default to 'medium' size
}) => {
  const [dateValue, setDateValue] = useState(value || defaultValue);
  const [isFocused, setIsFocused] = useState(false);
  const dateInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value !== undefined) {
      setDateValue(value);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDateValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleCalendarClick = () => {
    if (dateInputRef.current && !isDisabled) {
      dateInputRef.current.showPicker();
    }
  };

  const formatDisplayValue = () => {
    if (!dateValue) return placeholder;
    const date = new Date(dateValue);
    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    return `${placeholder}: ${formattedDate}`;
  };

  const sizeClasses = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-2.5 py-1.5 text-sm',
    large: 'px-3 py-2 text-base',
  };

  return (
    <div className="min-w-[200px] w-fit">
      <div className="relative">
        {loading ? (
          <div className="text-primary-dark font-semibold">Loading...</div>
        ) : (
          <>
            <input
              ref={dateInputRef}
              type="date"
              value={dateValue}
              onChange={handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              disabled={isDisabled}
              className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
            />
            <input
              type="text"
              value={formatDisplayValue()}
              readOnly
              disabled={isDisabled}
              className={`block w-full pr-10 border ${
                error ? 'border-red-500' : 'border-gray-300'
              } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                isDisabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'
              } ${sizeClasses[size]} ${!dateValue && !isFocused ? 'text-gray-400 italic' : 'text-black'}`}
              aria-invalid={!!error}
              aria-describedby={error ? 'error-text' : undefined}
            />
            <div 
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={handleCalendarClick}
            >
              <Calendar className="h-5 w-5 text-gray-400" />
            </div>
          </>
        )}
      </div>
      {error && <div id="error-text" className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );
};
