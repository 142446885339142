import React from 'react';
import { SelectSingle } from '../ui/SelectSingle';
import MeetingTypeContainer from '../../containers/MeetingTypeContainer';
import { MeetingType } from '../../api/meeting/types';
import { Icon } from '../ui';

interface MeetingTypeSelectProps {
  value: string | null;
  onChange: (selectedMeetingType: MeetingType | null) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  isOpen?: boolean;
  onClose?: () => void;
  showOpenButton?: boolean;
  contentType?: string;
}

const MeetingTypeSelect: React.FC<MeetingTypeSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = "Select Meeting Type",
  isOpen,
  onClose,
  showOpenButton,
  contentType,
}) => {
  return (
    <MeetingTypeContainer
      render={({ data: meetingTypes, loading, error, onCreate }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const meetingTypeOptions = meetingTypes.map((meetingType) => ({
          value: meetingType.id,
          label: meetingType.name,
          icon: <Icon name="Calendar" size="sm" color="text" />,
          slug: meetingType.slug,
        }));

        const handleSelect = (meetingTypeId: string) => {
          const selectedMeetingType = meetingTypes.find((mt) => mt.id === meetingTypeId) || null;
          onChange(selectedMeetingType);
        };

        const handleCreateNewMeetingType = async (newMeetingTypeName: string) => {
          // Create a minimal MeetingType object with default values
          const newMeetingType: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'> = {
            name: newMeetingTypeName,
            company: '', // You might want to set a default value or get this from context
            regular_attendees: [],
            tags: [],
            backlog_topics: [],
            recurrence: 'WEEKLY',
            duration: '30m',
            description: '',
          };
          onCreate(newMeetingType);
        };

        return (
          <SelectSingle
            placeholder={placeholder}
            options={meetingTypeOptions}
            defaultValue={value || undefined}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            onCreate={handleCreateNewMeetingType}
            entityName="meeting type"
            isOpen={isOpen}
            onClose={onClose}
            showOpenButton={showOpenButton}
            contentType={contentType}
            defaultIcon={<Icon name="Calendar" size="sm" color="text" />}
          />
        );
      }}
    />
  );
};

export default MeetingTypeSelect;