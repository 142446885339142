import React from 'react';
import { SelectSingle } from '../ui/SelectSingle';
import ProjectContainer from '../../containers/ProjectContainer';
import { Project } from '../../api/project/types';
import { Icon } from '../ui';

type SelectSize = 'small' | 'medium' | 'large';

interface ProjectSelectProps {
  value: string;
  onChange: (selectedProject: Project) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  className?: string;
  size?: SelectSize;
  isOpen?: boolean;
  onClose?: () => void;
  showOpenButton?: boolean;
  contentType?: string;
  personId?: string;
}

const ProjectSelect: React.FC<ProjectSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = 'Select a project',
  size = 'medium',
  isOpen,
  onClose,
  showOpenButton,
  contentType,
  personId,
}) => {
  return (
    <ProjectContainer
      personId={personId}
      render={({ data: projects, loading, error }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const projectOptions = projects.map((project) => ({
          value: project.id,
          label: project.title,
          icon: <Icon name="Layers" size="sm" color="text" />,
          slug: project.slug,
        }));

        const handleSelect = (projectId: string) => {
          const selectedProject = projects.find((project) => project.id === projectId);
          if (selectedProject) {
            onChange(selectedProject);
          }
        };

        return (
          <SelectSingle
            placeholder={placeholder}
            options={projectOptions}
            defaultValue={value}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            size={size}
            isOpen={isOpen}
            onClose={onClose}
            showOpenButton={showOpenButton}
            contentType={contentType}
            defaultIcon={<Icon name="Layers" size="sm" color="text" />}
          />
        );
      }}
    />
  );
};

export default ProjectSelect; 