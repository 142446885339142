import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import TaskContainer from '../../../containers/TaskContainer';
import ProjectContainer from '../../../containers/ProjectContainer';
import { Task } from '../../../api/task/types';
import { Project } from '../../../api/project/types';
import { Icon, InfoBox, Card, CardContent, CardHeader, CardTitle } from '../';

const formatDateForAPI = (date: Date): string => {
    return date.toISOString().split('T')[0];
  };
  
  const isDateInRange = (date: Date, startDate: Date | null, endDate: Date | null): boolean => {
    const formattedDate = formatDateForAPI(date);
    
    // If we have both dates, check if date falls within range
    if (startDate && endDate) {
      return date >= startDate && date <= endDate;
    }
    // If we only have one date, match that exactly
    return Boolean(
      (startDate && formatDateForAPI(startDate) === formattedDate) ||
      (endDate && formatDateForAPI(endDate) === formattedDate)
    );
  };
  
  export const CalendarPane = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const getDaysInMonth = (date: Date) => {
      const year = date.getFullYear();
      const month = date.getMonth();
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const firstDayOfMonth = new Date(year, month, 1).getDay();
      
      const days = [];
      for (let i = 0; i < firstDayOfMonth; i++) {
        days.push(null);
      }
      for (let i = 1; i <= daysInMonth; i++) {
        days.push(i);
      }
      return days;
    };

    
  
    // Get first and last day of current month
    const monthDateRange = useMemo(() => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        return {
          startDate: formatDateForAPI(new Date(year, month, 1)),
          endDate: formatDateForAPI(new Date(year, month + 1, 0))
        };
      }, [currentDate]);
      
      useEffect(() => {
        const unsubscribe = queryClient.getMutationCache().subscribe((event) => {
          if (event?.state?.status === 'success') {
            // Create the exact same query keys that the containers use
            const taskParams = { startDate: monthDateRange.startDate, endDate: monthDateRange.endDate };
            const projectParams = { startDate: monthDateRange.startDate, endDate: monthDateRange.endDate };

            // Invalidate using the exact same query key structure
            queryClient.invalidateQueries(['tasks', taskParams]);
            queryClient.invalidateQueries(['projects', projectParams]);
          }
        });
      
        return () => unsubscribe();
      }, [queryClient, monthDateRange]);
  
    const previousMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
    };
  
    const nextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
    };
  
    const handleDateClick = (day: number | null) => {
      if (day) {
        setSelectedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), day));
      }
    };

    const handleTaskClick = (task: Task) => {
        navigate(`/tasks/${task.slug}`);
      };
      
      const handleProjectClick = (project: Project) => {
        navigate(`/projects/${project.slug}`);
      };
  
    const days = getDaysInMonth(currentDate);
    const monthName = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();
    const selectedDayName = selectedDate.toLocaleDateString('default', { weekday: 'long' });
    const selectedDayNumber = selectedDate.getDate();
    const selectedMonthName = selectedDate.toLocaleDateString('default', { month: 'long' });
  
    
    return (
        <Card className="bg-white w-64 !p-0 !shadow-none !border-0">
          <CardHeader className="!px-4 !py-2">
            <div className="flex items-center mb-10 mt-2">
              <CardTitle className="text-md font-medium">
                <span>Calendar View</span>
              </CardTitle>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm font-medium text-text">
                {monthName} {year}
              </div>
              <div className="flex gap-1">
                <button
                  onClick={previousMonth}
                  className="p-1 hover:bg-background rounded-md"
                  title="Previous month"
                >
                  <Icon name="ChevronLeft" size="sm" />
                </button>
                <button
                  onClick={nextMonth}
                  className="p-1 hover:bg-background rounded-md"
                  title="Next month"
                >
                  <Icon name="ChevronRight" size="sm" />
                </button>
              </div>
            </div>
          </CardHeader>
          
          <CardContent className="!px-4 !py-2">
            <div className="grid grid-cols-7 gap-1 text-center text-xs mb-1">
              {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                <div key={day} className="font-medium text-text">
                  {day}
                </div>
              ))}
            </div>
    
            <TaskContainer
                startDate={monthDateRange.startDate}
                endDate={monthDateRange.endDate}
                render={({ data: monthTasks }) => (
                    <ProjectContainer
                    startDate={monthDateRange.startDate}
                    endDate={monthDateRange.endDate}
                    render={({ data: monthProjects }) => (
                        <>
                        <div className="grid grid-cols-7 gap-1 mb-4">
                            {days.map((day, index) => {
                            if (!day) return <div key={index} className="aspect-square p-1" />;

                            const currentDayStr = formatDateForAPI(
                                new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
                            );

                            const dayTasks = monthTasks.filter(task => {
                                const startDate = task.start_date ? new Date(task.start_date) : null;
                                const endDate = task.due_date ? new Date(task.due_date) : null;
                                const currentDayDate = new Date(currentDayStr);
                                return isDateInRange(currentDayDate, startDate, endDate);
                            });

                            const dayProjects = monthProjects.filter(project => {
                                const startDate = project.start_date ? new Date(project.start_date) : null;
                                const endDate = project.target_date ? new Date(project.target_date) : null;
                                const currentDayDate = new Date(currentDayStr);
                                return isDateInRange(currentDayDate, startDate, endDate);
                            });

                            return (
                              <div
                              className={`aspect-square p-1 text-xs flex flex-col items-center justify-center relative
                                  hover:bg-background cursor-pointer
                                  ${day === selectedDate.getDate() && currentDate.getMonth() === selectedDate.getMonth() 
                                  ? 'bg-primary/10 text-primary' 
                                  : ''}`}
                              key={index}
                              onClick={() => handleDateClick(day)}
                            >
                              <InfoBox
                                content={{
                                  title: `${monthName} ${day}, ${year}`,
                                  items: [
                                    { key: 'Tasks', value: `${dayTasks.length} scheduled` },
                                    { key: 'Projects', value: `${dayProjects.length} scheduled` }
                                  ]
                                }}
                                position="left"
                                delay={200}
                              >
                                <div className="flex flex-col items-center justify-center relative z-0">
                                  <div className="font-medium">{day}</div>
                                  <div className="flex space-x-1 mt-1">
                                    {dayTasks.length > 0 && (
                                      <div className="w-1.5 h-1.5 bg-primary rounded-full" />
                                    )}
                                    {dayProjects.length > 0 && (
                                      <div className="w-1.5 h-1.5 bg-secondary rounded-full" />
                                    )}
                                  </div>
                                </div>
                              </InfoBox>
                            </div>
                            
                            );
                            })}
                        </div>

                        <div className="border-t border-neutral pt-2">
                            <div className="font-bold text-sm mb-2 text-text mt-2">
                            {selectedDayName}, {selectedMonthName} {selectedDayNumber}
                            </div>
                            
                            <div className="mb-3">
                            <div className="text-sm font-medium text-text mb-1">Tasks</div>
                            {monthTasks
                                .filter(task => {
                                    const startDate = task.start_date ? new Date(task.start_date) : null;
                                    const endDate = task.due_date ? new Date(task.due_date) : null;
                                    const selectedDateObj = new Date(formatDateForAPI(selectedDate));
                                    return isDateInRange(selectedDateObj, startDate, endDate);
                                })
                                .map(task => (
                                    <div 
                                    key={task.slug} 
                                    className="flex items-center gap-2 text-xs mb-1 hover:bg-background cursor-pointer rounded px-1 py-0.5"
                                    onClick={() => handleTaskClick(task)}
                                    >
                                    <Icon 
                                        name="CheckCircle" 
                                        size="md"
                                        className={task.state === 'IDENTIFIED' ? 'text-primary' : 
                                                task.state === 'IN_PROGRESS' ? 'text-secondary' : 
                                                'text-accent'}
                                    />
                                    <span>{task.title}</span>
                                    </div>
                                ))
                            }
                            {monthTasks.filter(task => {
                                const startDate = task.start_date ? new Date(task.start_date) : null;
                                const endDate = task.due_date ? new Date(task.due_date) : null;
                                const selectedDateObj = new Date(formatDateForAPI(selectedDate));
                                return isDateInRange(selectedDateObj, startDate, endDate);
                            }).length === 0 && (
                                <div className="text-xs text-placeholder">No tasks scheduled</div>
                            )}
                            </div>
                            
                            <div>
                            <div className="text-sm font-medium text-text mb-1">Projects</div>
                            {monthProjects
                                .filter(project => {
                                    const startDate = project.start_date ? new Date(project.start_date) : null;
                                    const endDate = project.target_date ? new Date(project.target_date) : null;
                                    const selectedDateObj = new Date(formatDateForAPI(selectedDate));
                                    return isDateInRange(selectedDateObj, startDate, endDate);
                                })
                                .map(project => (
                                    <div 
                                    key={project.slug} 
                                    className="flex items-center gap-2 text-xs mb-1 hover:bg-background cursor-pointer rounded px-1 py-0.5"
                                    onClick={() => handleProjectClick(project)}
                                    >
                                    <Icon 
                                        name="Layers" 
                                        size="md"
                                        className="text-secondary"
                                    />
                                    <span>{project.title}</span>
                                    </div>
                                ))
                            }
                            {monthProjects.filter(project => {
                                const startDate = project.start_date ? new Date(project.start_date) : null;
                                const endDate = project.target_date ? new Date(project.target_date) : null;
                                const selectedDateObj = new Date(formatDateForAPI(selectedDate));
                                return isDateInRange(selectedDateObj, startDate, endDate);
                            }).length === 0 && (
                                <div className="text-xs text-placeholder">No projects scheduled</div>
                            )}
                            </div>
                        </div>
                        </>
                    )}
                    />
                )}
                />
            </CardContent>
        </Card>
    );
};

export default CalendarPane;