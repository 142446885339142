import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ModalContent, SelectSingle } from '../ui';
import { Topic } from '../../api/topic/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import PersonSelectMulti from '../person/PersonSelectMulti';
import TagSelect from '../tags/TagSelect'; 
import TaskPanel from '../task/TaskPanel';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';
import TopicContainer from '../../containers/TopicContainer';
import FavoriteContainer from '../../containers/FavoriteContainer';
import { topicStateOptions, heatmapStatusOptions, importanceOptions, getStateLabels } from '../../utils/entitiesOptions';
import { useDescriptionDraft } from '../../hooks/useDescriptionDraft';

interface TopicModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
  initialData?: Omit<Topic, 'created_at' | 'updated_at'>;
  meetingTypeId?: string;
  isFullscreen?: boolean;
  parentModalViewMode?: 'modal' | 'fullscreen' | 'sidePeek';
  personId?: string;
}

export const TopicModal: React.FC<TopicModalProps> = ({ 
  isOpen, 
  onClose, 
  onSuccess, 
  onError, 
  initialData, 
  meetingTypeId,
  isFullscreen = false,
  parentModalViewMode = 'modal',
  personId
}) => {
  const [isEditMode, setIsEditMode] = useState(!!initialData?.id);
  
  // Add useDescriptionDraft hook for new topics only
  const {
    description: draftDescription,
    hasUnsavedDraft,
    handleDescriptionChange: handleDraftChange,
    clearDraft
  } = useDescriptionDraft({
    entityType: 'topic',
    entityId: initialData?.id,
    initialDescription: '',
    isOpen: isOpen && !initialData  // Only active for new topics
  });

  const [topicData, setTopicData] = useState<Partial<Topic>>({
    title: '',
    description: '',
    state: 'IDENTIFIED',
    importance: 1,
    heatmap_status: 'GREEN',
    persons: personId ? [{ id: personId }] as Person[] : [],
    tags: [],
    company: '',
    visibility: 'private',
    editable_visibility: false,
  });
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [modalViewMode, setModalViewMode] = useState<'modal' | 'fullscreen' | 'sidePeek'>('modal');
  const initialTitle = useRef('');
  const initialDescription = useRef('');
  const editFunctionRef = useRef<((slug: string, updatedTopic: Partial<Topic>) => void) | null>(null);

  useEffect(() => {
    if (initialData) {
      setIsEditMode(!!initialData.id);
      if (!topicData.id || topicData.id !== initialData.id) {
        setTopicData({
          ...initialData,
          description: initialData.description || '',
          visibility: initialData.visibility || 'private',
          editable_visibility: initialData.editable_visibility || false,
        });
        initialTitle.current = initialData.title || '';
        initialDescription.current = initialData.description || '';
      }
    } else {
      // For new topics, initialize with draft if exists
      setTopicData(prev => ({
        ...prev,
        title: '',
        description: draftDescription || '',  // Use draft description if available
        state: 'IDENTIFIED',
        importance: 1,
        heatmap_status: 'GREEN',
        persons: personId ? [{ id: personId }] as Person[] : [],
        tags: [],
        company: '',
        visibility: 'private',
        editable_visibility: false,
      }));
    }
  }, [initialData, personId, draftDescription]);  
  
  useEffect(() => {
    if (isOpen && initialData?.id) {
      setIsEditMode(true); // Ensure edit mode if modal opens with initialData
    } else if (!isOpen) {
      setIsEditMode(false); // Reset edit mode when modal closes
    }
  }, [isOpen, initialData?.id]);
  
  // Save on refresh/page unload
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isEditMode && initialData?.slug && editFunctionRef.current && topicData.description !== initialDescription.current) {
        editFunctionRef.current(initialData.slug, { 
          description: topicData.description 
        });
        initialDescription.current = topicData.description || '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isEditMode, initialData?.slug, topicData.description]);

  // Autosave after 30 minutes of inactivity
  useEffect(() => {
    if (!isEditMode || !initialData?.slug || !editFunctionRef.current || topicData.description === initialDescription.current) return;

    const autoSaveTimer = setTimeout(() => {
      if (initialData?.slug && editFunctionRef.current && topicData.description !== initialDescription.current) {
        editFunctionRef.current(initialData.slug, { 
          description: topicData.description 
        });
        initialDescription.current = topicData.description || '';
      }
    }, 30 * 60 * 1000); // 30 minutes

    return () => clearTimeout(autoSaveTimer);
  }, [isEditMode, topicData.description, initialData?.slug]);

  const handleChange = useCallback(
    (field: keyof Topic, value: any, onEdit?: (slug: string, updatedTopic: Partial<Topic>) => void) => {
      setTopicData((prevData) => ({ ...prevData, [field]: value }));
  
      // Only trigger edit if we have a real change
      if (isEditMode && initialData?.slug && onEdit && field !== 'description') {
        onEdit(initialData.slug, { [field]: value });
      }
    },
    [isEditMode, initialData]
  );

  const handleTitleBlur = useCallback((onEdit: (slug: string, updatedTopic: Partial<Topic>) => void) => {
    if (isEditMode && initialData?.slug && topicData.title !== initialTitle.current) {
      onEdit(initialData.slug, { title: topicData.title });
      initialTitle.current = topicData.title || '';
    }
  }, [isEditMode, initialData, topicData.title]);

  const handleDescriptionChange = useCallback((newDescription: string) => {
    if (!initialData) {
      // For new topics, use draft functionality
      handleDraftChange(newDescription);
    }
    
    setTopicData(prev => ({
      ...prev,
      description: newDescription || ''
    }));
  }, [initialData, handleDraftChange]);

  const handleSave = useCallback((onCreate: (topicData: Omit<Topic, 'id' | 'created_at' | 'updated_at' | 'slug'>) => void, onEdit: (slug: string, updatedTopic: Partial<Topic>) => void) => {
    if (!topicData.title) {
      onError('Please provide a title');
      return;
    }

    const topicToSave = {
      ...topicData,
      created_by: initialData?.created_by || {} as Person,
      company: initialData?.company || '',
    } as Omit<Topic, 'id' | 'created_at' | 'updated_at' | 'slug'>;

    if (isEditMode && initialData?.slug) {
      onEdit(initialData.slug, topicToSave);
      initialDescription.current = topicData.description || '';
      onSuccess('Topic updated successfully');
    } else {
      onCreate(topicToSave);
      clearDraft(); // Clear draft after successful creation
      onSuccess('Topic created successfully');
    }
    onClose();
  }, [isEditMode, topicData, initialData, onSuccess, onError, onClose, clearDraft]);

  const handleClose = useCallback((onEdit: (slug: string, updatedTopic: Partial<Topic>) => void) => {
    if (isEditMode && initialData?.slug && topicData.description !== initialDescription.current) {
      onEdit(initialData.slug, { description: topicData.description });
      initialDescription.current = topicData.description || '';
    }
    onClose();
  }, [isEditMode, initialData, topicData.description, onClose]);

  const handleDelete = useCallback((onDelete: (slug: string) => void) => {
    if (isEditMode && initialData?.slug) {
      onDelete(initialData.slug);
      onSuccess('Topic deleted successfully');
      onClose();
    }
  }, [isEditMode, initialData, onSuccess, onClose]);

  const toggleSidePanel = useCallback(() => {
    setIsSidePanelOpen(prev => !prev);
  }, []);

  if (!isOpen) return null;

  const lifecycleStates = ['IDENTIFIED', 'IN_PROGRESS', 'ONGOING', 'COMPLETED', 'ARCHIVED'];
  const friendlyLifecycleStates = getStateLabels(lifecycleStates, topicStateOptions);

  const showEditModeContent = isEditMode && !!initialData;

  return (
    <TopicContainer
      render={({ onCreate, onEdit, onDelete }) => (
        <FavoriteContainer
          type="topic.topic"
          render={({ onToggle, isFavorited }) => {
            editFunctionRef.current = onEdit;

            const handleVisibilityToggle = (isPublic: boolean) => {
              const newVisibility = isPublic ? 'public' : 'private';
              handleChange('visibility', newVisibility, onEdit);
            };

            return (
              <ModalContent
                title={topicData.title || ''}
                description={topicData.description || ''}
                showWarning={!initialData && hasUnsavedDraft}
                warningTooltip={!initialData && hasUnsavedDraft ? 'You have an unsaved draft' : undefined}
                onCancel={() => handleClose(onEdit)}
                onCreate={() => handleSave(onCreate, onEdit)}
                onDelete={showEditModeContent ? () => handleDelete(onDelete) : undefined}
                onTitleChange={(newTitle) => setTopicData(prevData => ({ ...prevData, title: newTitle }))}
                onDescriptionChange={handleDescriptionChange}
                onTitleBlur={() => handleTitleBlur(onEdit)}
                actionButtonLabel={isEditMode ? 'Save Changes' : 'Create Topic'}
                viewMode={modalViewMode}
                isSidePanelInitiallyCollapsed={true}
                showSidePanel={showEditModeContent}
                showFooterButtons={true}
                sidePanelContent={
                  showEditModeContent && initialData && (
                    <TaskPanel
                      isOpen={isSidePanelOpen}
                      onClose={toggleSidePanel}
                      topicId={initialData.id}
                      title="Related Tasks"
                    />
                  )
                }
                selects={[
                  {
                    component: SelectSingle,
                    props: {
                      options: importanceOptions.reverse(),
                      placeholder: 'Select Importance',
                      defaultValue: topicData.importance?.toString(),
                      onSelect: (value: string) => handleChange('importance', Number(value), onEdit),
                    }
                  },
                  {
                    component: SelectSingle,
                    props: {
                      options: topicStateOptions,
                      placeholder: 'Select State',
                      defaultValue: topicData.state,
                      onSelect: (value: string) => handleChange('state', value as Topic['state'], onEdit),
                    }
                  },
                  {
                    component: SelectSingle,
                    props: {
                      options: heatmapStatusOptions,
                      placeholder: 'Select Heatmap Status',
                      defaultValue: topicData.heatmap_status,
                      onSelect: (value: string) => handleChange('heatmap_status', value as Topic['heatmap_status'], onEdit),
                    }
                  },
                  {
                    component: PersonSelectMulti,
                    props: {
                      value: topicData.persons?.map(person => person.id) || [],
                      onChange: (newPersons: Person[]) => handleChange('persons', newPersons, onEdit),
                      enableSearch: true,
                    }
                  },
                  {
                    component: TagSelect,
                    props: {
                      value: topicData.tags?.map(tag => tag.id) || [],
                      onChange: (newTags: Tag[]) => handleChange('tags', newTags, onEdit),
                      enableSearch: true,
                    }
                  }
                ]}
                activityFeed={showEditModeContent && initialData ? (
                  <LogEntryActivityFeed objectId={initialData.id} objectType='topic' showTimeline={true} />
                ) : null}
                showActivityFeed={showEditModeContent}
                onVisibilityToggle={handleVisibilityToggle}
                initialVisibility={topicData.visibility}
                isVisibilityEditable={topicData.editable_visibility}
                parentObjectType="topic"
                parentObjectId={showEditModeContent && initialData ? initialData.id : ''}
                lifecycleStates={friendlyLifecycleStates}
                currentState={getStateLabels([topicData?.state || ''], topicStateOptions)[0]}
                showLifecycle={true}
                descriptionSize='large'
                showFavorite={showEditModeContent}
                isFavorited={showEditModeContent && isFavorited(initialData?.id || '')}
                onFavoriteToggle={() => initialData?.id && onToggle('topic.topic', initialData.id)}
                isNestedModal={parentModalViewMode === 'fullscreen'}
                onViewModeChange={(mode) => {
                  setModalViewMode(mode);
                }}
              />
            );
          }}
        />
      )}
    />
  );
};