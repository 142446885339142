import { Tabs } from '../ui';
import MeetingOccurrenceTopicPanel from './MeetingOccurrenceTopicPanel';
import TaskPanel from '../task/TaskPanel';
import MeetingPastOccurrencesPanel from './MeetingPastOccurrencesPanel';
import { Topic } from '../../api/topic/types';

interface MeetingOccurrenceSidePanelProps {
  meetingTypeId: string;
  currentMeetingOccurrenceId?: string;
  onTopicClick?: (topic: Topic) => void;
}

export function MeetingOccurrenceSidePanel({
  meetingTypeId,
  currentMeetingOccurrenceId,
  onTopicClick,
}: MeetingOccurrenceSidePanelProps) {
  const tabs = [
    {
      label: 'Open Topics',
      content: (
        <MeetingOccurrenceTopicPanel
          isOpen={true}
          onClose={() => {}}
          meetingTypeId={meetingTypeId}
          onTopicClick={onTopicClick}
        />
      ),
    },
    {
      label: 'Open Tasks',
      content: (
        <TaskPanel
          isOpen={true}
          onClose={() => {}}
          meetingTypeId={meetingTypeId}
          meetingOccurrenceId={currentMeetingOccurrenceId}
        />
      ),
    },
    {
      label: 'Past Meetings',
      content: (
        <MeetingPastOccurrencesPanel
          isOpen={true}
          onClose={() => {}}
          meetingTypeId={meetingTypeId}
          currentMeetingOccurrenceId={currentMeetingOccurrenceId}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full max-w-md p-4">
      <Tabs tabs={tabs} />
    </div>
  );
}

export default MeetingOccurrenceSidePanel;
