import React from 'react';
import { Icon, IconName } from '../../ui/';

interface SidePanelItemProps {
  title: string;
  message?: string;
  icon?: {
    name: IconName;
    className?: string;
  };
  prefix?: string;
  borderColor?: string;
  metadata?: {
    state?: string;
    project?: string;
    dial_stage?: string;
    heatmap_status?: string;
    importance?: string;
    owner?: string;
    start_date?: string;
    due_date?: string;
  };
  onClick?: () => void;
  children?: React.ReactNode;
}

export const SidePanelItem: React.FC<SidePanelItemProps> = ({
  title,
  message,
  icon,
  prefix,
  borderColor = 'border-l-gray-300',
  metadata,
  onClick
}) => {
  return (
    <div
      onClick={onClick}
      className={`
        flex flex-col gap-1 p-2 rounded-md
        border-l-2 ${borderColor}
        hover:bg-gray-100 transition-all duration-200
        ${onClick ? 'cursor-pointer' : ''}
      `}
    >
      <div className="flex items-center gap-2">
        {icon && (
          <Icon
            name={icon.name}
            size="sm"
            className={icon.className}
          />
        )}
        <span className="text-sm font-medium truncate">
          {prefix && (
            <span className="text-gray-500">{prefix}</span>
          )}
          {' '}
          {title}
        </span>
      </div>
      
      {message && (
        <div className="text-xs text-gray-500">
          {message}
        </div>
      )}
      
      {metadata?.state && (
        <div className="flex items-center gap-1 text-xs text-gray-400">
          <Icon name="Activity" size="sm" />
          <span>{metadata.state}</span>
        </div>
      )}
      
      {metadata?.project && (
        <div className="flex items-center gap-1 text-xs text-gray-400">
          <Icon name="Folder" size="sm" />
          <span>{metadata.project}</span>
        </div>
      )}
      
      {metadata?.dial_stage && (
        <div className="flex items-center gap-1 text-xs text-gray-400">
          <Icon name="GitCommit" size="sm" />
          <span>{metadata.dial_stage}</span>
        </div>
      )}

      {metadata?.heatmap_status && (
        <div className="flex items-center gap-1 text-xs text-gray-400">
          <Icon name="Thermometer" size="sm" />
          <span>{metadata.heatmap_status}</span>
        </div>
      )}

      {metadata?.importance && (
        <div className="flex items-center gap-1 text-xs text-gray-400">
          <Icon name="Signal" size="sm" />
          <span>{metadata.importance}</span>
        </div>
      )}
      
      {metadata?.owner && (
        <div className="flex items-center gap-1 text-xs text-gray-400">
          <Icon name="User" size="sm" />
          <span>{metadata.owner}</span>
        </div>
      )}
      
      {(metadata?.start_date || metadata?.due_date) && (
        <div className="flex items-center gap-2 text-xs text-gray-400 mt-1">
          {metadata.start_date && (
            <div className="flex items-center gap-1">
              <Icon name="Calendar" size="sm" />
              <span>Start: {metadata.start_date}</span>
            </div>
          )}
          {metadata.due_date && (
            <div className="flex items-center gap-1">
              <Icon name="Clock" size="sm" />
              <span>Due: {metadata.due_date}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};