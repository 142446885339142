import React from 'react';
import { SelectSingle } from '../ui/SelectSingle';
import TopicContainer from '../../containers/TopicContainer';
import { Topic } from '../../api/topic/types';
import { Icon } from '../ui';

type SelectSize = 'small' | 'medium' | 'large';

interface TopicSelectProps {
  value: string;
  onChange: (selectedTopic: Topic) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  className?: string;
  size?: SelectSize;
  isOpen?: boolean;
  onClose?: () => void;
  showOpenButton?: boolean;
  contentType?: string;
  personId?: string;
}

const TopicSelect: React.FC<TopicSelectProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = 'Select a topic',
  size = 'medium',
  isOpen,
  onClose,
  showOpenButton,
  contentType,
  personId,
}) => {
  return (
    <TopicContainer
      personId={personId}
      render={({ data: topics, loading, error }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const topicOptions = topics.map((topic) => ({
          value: topic.id,
          label: topic.title,
          icon: <Icon name="Library" size="sm" color="text" />,
          slug: topic.slug,
        }));

        const handleSelect = (topicId: string) => {
          const selectedTopic = topics.find((topic) => topic.id === topicId);
          if (selectedTopic) {
            onChange(selectedTopic);
          }
        };

        return (
          <SelectSingle
            placeholder={placeholder}
            options={topicOptions}
            defaultValue={value}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            size={size}
            isOpen={isOpen}
            onClose={onClose}
            showOpenButton={showOpenButton}
            contentType={contentType}
            defaultIcon={<Icon name="Library" size="sm" color="text" />}
          />
        );
      }}
    />
  );
};

export default TopicSelect;
