import React from 'react';
import { Tabs } from '../ui';
import TaskPanel from '../task/TaskPanel';
import MeetingPastOccurrencesPanel from './MeetingPastOccurrencesPanel';
import TopicSelectPanel from '../topic/TopicSelectPanel';
import { Topic } from '../../api/topic/types';

interface MeetingTypeSidePanelProps {
  meetingTypeId: string;
  selectedTopics: Topic[];
  onTopicSelect: (topic: Topic) => void;
  onTopicDeselect: (topicId: string) => void;
}

export function MeetingTypeSidePanel({
  meetingTypeId,
  selectedTopics,
  onTopicSelect,
  onTopicDeselect,
}: MeetingTypeSidePanelProps) {
  const tabs = [
    {
      label: 'Topics',
      content: (
        <TopicSelectPanel
          isOpen={true}
          meetingTypeId={meetingTypeId}
          selectedTopics={selectedTopics}
          onTopicSelect={onTopicSelect}
          onTopicDeselect={onTopicDeselect}
        />
      ),
    },
    {
      label: 'Tasks',
      content: (
        <TaskPanel
          isOpen={true}
          meetingTypeId={meetingTypeId}
          showAddTaskButton={false}
        />
      ),
    },
    {
      label: 'Past Meetings',
      content: (
        <MeetingPastOccurrencesPanel
          isOpen={true}
          meetingTypeId={meetingTypeId}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full max-w-md p-4">
      <Tabs tabs={tabs} />
    </div>
  );
}

export default MeetingTypeSidePanel;