import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from './base/Icon';
import { Tooltip } from './feedback/Tooltip';
import { getSlugPath } from '../../utils/contentTypeConfig';

type SelectOption = {
  label: string;
  value: string;
  icon?: React.ReactNode;
  slug?: string;
};

type SelectSize = 'small' | 'medium' | 'large';

interface SelectSingleProps {
  options: SelectOption[];
  placeholder?: string;
  defaultValue?: string;
  defaultIcon?: React.ReactNode;
  onSelect: (value: string) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  size?: SelectSize;
  isOpen?: boolean;
  onClose?: () => void;
  onCreate?: (newValue: string) => void;
  entityName?: string;
  showOpenButton?: boolean;
  contentType?: string;
}

export const SelectSingle: React.FC<SelectSingleProps> = ({
  options,
  placeholder = 'Select an option',
  defaultValue = '',
  defaultIcon,
  onSelect,
  isDisabled = false,
  enableSearch = true,
  size = 'medium',
  isOpen,
  onClose,
  onCreate,
  entityName = 'item',
  showOpenButton = false,
  contentType,
}) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null);
  const [internalDropdownOpen, setInternalDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options);
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');
  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const dropdownOpen = isOpen !== undefined ? isOpen : internalDropdownOpen;

  const sizeClasses = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-2.5 py-1.5 text-sm',
    large: 'px-3 py-2 text-base',
  };

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find(option => option.value === defaultValue) || null;
      setSelectedOption(defaultOption);
    }
  }, [defaultValue, options]);

  useEffect(() => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchQuery, options]);

  // Find the closest scrollable parent
  const getScrollParent = (element: HTMLElement | null): HTMLElement | null => {
    if (!element) return null;

    const isScrollable = (el: HTMLElement) => {
      const style = window.getComputedStyle(el);
      return style.overflow === 'auto' || style.overflow === 'scroll' || 
             style.overflowY === 'auto' || style.overflowY === 'scroll';
    };

    let parent = element.parentElement;
    while (parent && !isScrollable(parent)) {
      parent = parent.parentElement;
    }
    return parent || document.body;
  };

  useEffect(() => {
    if (dropdownOpen && selectRef.current && dropdownRef.current) {
      const selectRect = selectRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const scrollParent = getScrollParent(selectRef.current);
      
      let spaceBelow = window.innerHeight - selectRect.bottom;
      let spaceAbove = selectRect.top;

      if (scrollParent && scrollParent !== document.body) {
        const scrollParentRect = scrollParent.getBoundingClientRect();
        spaceBelow = Math.min(spaceBelow, scrollParentRect.bottom - selectRect.bottom);
        spaceAbove = Math.min(spaceAbove, selectRect.top - scrollParentRect.top);
      }

      setDropdownPosition(spaceBelow >= dropdownHeight || spaceBelow >= spaceAbove ? 'bottom' : 'top');
    }
  }, [dropdownOpen, searchQuery, filteredOptions]);

  const handleSelect = (option: SelectOption) => {
    setSelectedOption(option);
    onSelect(option.value);
    setSearchQuery('');
    if (onClose) {
      onClose();
    } else {
      setInternalDropdownOpen(false);
    }
  };

  const handleCreate = () => {
    if (onCreate && searchQuery.trim()) {
      onCreate(searchQuery.trim());
      setSearchQuery('');
    }
  };

  const toggleDropdown = () => {
    if (!isDisabled) {
      if (onClose) {
        onClose();
      } else {
        setInternalDropdownOpen(!internalDropdownOpen);
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        if (onClose) {
          onClose();
        } else {
          setInternalDropdownOpen(false);
        }
      }
    };

    if (dropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownOpen, onClose]);

  const handleOpenRecord = () => {
    if (selectedOption && contentType) {
      const path = getSlugPath(contentType);
      if (path) {
        const selectedData = options.find(opt => opt.value === selectedOption.value);
        if (selectedData?.slug) {
          navigate(`/${path}/${selectedData.slug}`);
        }
      }
    }
  };

  return (
    <div className="relative inline-block" ref={selectRef}>
      <div className={`flex items-center ${showOpenButton ? 'space-x-2' : ''}`}>
        <button
          className={`flex items-center space-x-2 border border-neutral rounded-md shadow-sm cursor-pointer bg-white focus:outline-none focus:border-primary ${sizeClasses[size]} ${
            isDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={toggleDropdown}
          disabled={isDisabled}
        >
          {selectedOption?.icon ? (
            <span className="text-text">
              {React.cloneElement(selectedOption?.icon as React.ReactElement<any>, {
                color: 'text',
              })}
            </span>
          ) : (
            defaultIcon && <span className="text-text">{defaultIcon}</span>
          )}
          <span className={`pr-1 ${selectedOption ? 'text-text' : 'text-gray-400 italic'}`}>
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          
        </button>

        {showOpenButton && selectedOption && contentType && (
          <Tooltip content="Open record">
            <button
              onClick={handleOpenRecord}
              className="p-1"
              disabled={!selectedOption || !contentType}
            >
              <Icon name="ExternalLink" size="sm" color="primary" />
            </button>
          </Tooltip>
        )}
      </div>

      {dropdownOpen && !isDisabled && (
        <div 
          ref={dropdownRef}
          className={`absolute z-50 w-auto min-w-[200px] border border-neutral bg-white rounded-md shadow-lg max-h-60 overflow-y-auto ${
            dropdownPosition === 'top' 
              ? 'bottom-full mb-2' 
              : 'top-full mt-2'
          }`}
        >
          {enableSearch && (
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-3 py-1.5 border border-neutral rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-primary"
                disabled={isDisabled}
              />
            </div>
          )}

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`cursor-pointer px-3 py-1.5 hover:bg-neutral flex items-center justify-between text-sm ${
                  selectedOption?.value === option.value ? 'bg-primary text-white' : ''
                }`}
                onClick={() => handleSelect(option)}
              >
                <div className="flex items-center space-x-2">
                  {option.icon && (
                    <span>
                      {React.cloneElement(option.icon as React.ReactElement<any>, {
                        color: selectedOption?.value === option.value ? 'white' : 'text',
                      })}
                    </span>
                  )}
                  <span>{option.label}</span>
                </div>
                {selectedOption?.value === option.value && (
                  <svg
                    className="w-4 h-4 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500 text-sm">No options found</div>
          )}

          {onCreate && searchQuery && !filteredOptions.length && (
            <div
              className="cursor-pointer px-3 py-1.5 hover:bg-neutral flex items-center justify-between text-primary text-sm"
              onClick={handleCreate}
            >
              Create new {entityName}: "{searchQuery}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};