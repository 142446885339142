import React, { useState, useEffect, useRef } from 'react';
import { Icon } from './base/Icon';

export type SelectOption = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

interface SelectMultiProps {
  options: SelectOption[];
  placeholder?: string;
  defaultValues?: string[];
  defaultIcon?: React.ReactNode;
  onSelect: (values: string[]) => void;
  onCreate?: (label: string) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  entityName?: string;
  renderSummary?: (selectedOptions: SelectOption[]) => React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}

export const SelectMulti: React.FC<SelectMultiProps> = ({
  options,
  placeholder = 'Select options',
  defaultValues = [],
  defaultIcon,
  onSelect,
  onCreate,
  isDisabled = false,
  enableSearch = true,
  entityName = 'item',
  renderSummary,
  isOpen,
  onClose,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [internalDropdownOpen, setInternalDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options);
  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');
  const selectRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dropdownOpen = isOpen !== undefined ? isOpen : internalDropdownOpen;

  useEffect(() => {
    if (defaultValues.length > 0) {
      const defaultSelectedOptions = options.filter(option => defaultValues.includes(option.value));
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [defaultValues, options]);

  useEffect(() => {
    const filtered = options.filter(option =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchQuery, options]);

  // Calculate dropdown position when it opens
  useEffect(() => {
    if (dropdownOpen && selectRef.current && dropdownRef.current) {
      const selectRect = selectRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - selectRect.bottom;
      const spaceAbove = selectRect.top;

      // If there's not enough space below and more space above, show dropdown on top
      if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
        setDropdownPosition('top');
      } else {
        setDropdownPosition('bottom');
      }
    }
  }, [dropdownOpen, searchQuery, filteredOptions]);

  const handleSelect = (option: SelectOption) => {
    let updatedSelectedOptions: SelectOption[];

    if (selectedOptions.some(selected => selected.value === option.value)) {
      updatedSelectedOptions = selectedOptions.filter(selected => selected.value !== option.value);
    } else {
      updatedSelectedOptions = [...selectedOptions, option];
    }

    setSelectedOptions(updatedSelectedOptions);
    onSelect(updatedSelectedOptions.map(option => option.value));
  };

  const handleCreateNew = () => {
    if (onCreate) {
      onCreate(searchQuery);
      setSearchQuery('');
    }
  };

  const toggleDropdown = () => {
    if (!isDisabled) {
      if (onClose) {
        onClose();
      } else {
        setInternalDropdownOpen(!internalDropdownOpen);
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        if (onClose) {
          onClose();
        } else {
          setInternalDropdownOpen(false);
        }
      }
    };

    if (dropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownOpen, onClose]);

  return (
    <div className="relative inline-block" ref={selectRef}>
      <button
        className={`flex items-center space-x-2 border border-neutral rounded-md shadow-sm cursor-pointer bg-white focus:outline-none focus:border-primary px-2.5 py-1.5 text-sm ${
          isDisabled ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={toggleDropdown}
        disabled={isDisabled}
      >
        {selectedOptions.length > 0 ? (
          renderSummary ? (
            <div className="flex items-center space-x-3">
              {renderSummary(selectedOptions)}
            </div>
          ) : (
            <div className="flex items-center space-x-3">
              {selectedOptions.map(option => (
                <span key={option.value} className="flex items-center space-x-2">
                  {option.icon && (
                    <span>
                      {React.cloneElement(option.icon as React.ReactElement<any>, { color: 'text' })}
                    </span>
                  )}
                  <span>{option.label}</span>
                </span>
              ))}
            </div>
          )
        ) : (
          <div className="flex items-center space-x-1 pr-1">
            {defaultIcon && <span className="text-text">{defaultIcon}</span>}
            <span className="text-gray-400 italic">{placeholder}</span>
          </div>
        )}
      </button>

      {dropdownOpen && !isDisabled && (
        <div 
          ref={dropdownRef}
          className={`absolute z-10 w-auto min-w-[200px] border border-neutral bg-white rounded-md shadow-lg max-h-60 overflow-auto ${
            dropdownPosition === 'top' 
              ? 'bottom-full mb-2' 
              : 'top-full mt-2'
          }`}
        >
          {enableSearch && (
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-3 py-1.5 border border-neutral rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-primary"
                disabled={isDisabled}
              />
            </div>
          )}

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`cursor-pointer px-3 py-1.5 hover:bg-neutral flex items-center justify-between text-sm ${
                  selectedOptions.some(selected => selected.value === option.value) ? 'bg-primary text-white' : ''
                }`}
                onClick={() => handleSelect(option)}
              >
                <div className="flex items-center space-x-2">
                  {option.icon && (
                    <span>
                      {React.cloneElement(option.icon as React.ReactElement<any>, {
                        color: selectedOptions.some(selected => selected.value === option.value) ? 'white' : 'text',
                      })}
                    </span>
                  )}
                  <span>{option.label}</span>
                </div>
                {selectedOptions.some(selected => selected.value === option.value) && (
                  <svg
                    className="w-4 h-4 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                )}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500 text-sm">No options found</div>
          )}

          {onCreate && searchQuery && !filteredOptions.length && (
            <div
              className="cursor-pointer px-3 py-1.5 hover:bg-neutral flex items-center justify-between text-primary text-sm"
              onClick={handleCreateNew}
            >
              Create new {entityName}: "{searchQuery}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};