import React from 'react';
import { useQueryClient } from 'react-query';
import { useMeetingTypes, useCreateMeetingType, useEditMeetingType, useDeleteMeetingType } from '../api/meeting/queries';
import { MeetingType } from '../api/meeting/types';

interface MeetingTypeContainerProps {
  personId?: string;
  topicId?: string;
  render: (props: {
    data: MeetingType[];
    loading: boolean;
    error: string | null;
    onCreate: (newMeetingType: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>) => Promise<MeetingType>;
    onEdit: (slug: string, updatedMeetingType: Partial<MeetingType>) => void;
    onDelete: (slug: string) => void;
  }) => JSX.Element;
}

const MeetingTypeContainer: React.FC<MeetingTypeContainerProps> = ({ personId, topicId, render }) => {
  const params = { personId, topicId };
  const queryClient = useQueryClient();
  
  const queryKey = ['meetingTypes', params];

  const { data: meetingTypes = [], isLoading, error } = useMeetingTypes();
  const createMutation = useCreateMeetingType();
  const editMutation = useEditMeetingType();
  const deleteMutation = useDeleteMeetingType();

  const handleCreate = async (newMeetingType: Omit<MeetingType, 'id' | 'created_at' | 'updated_at' | 'slug'>): Promise<MeetingType> => {
    const result = await createMutation.mutateAsync(newMeetingType);

    // Directly update cache after successful mutation
    queryClient.setQueryData(queryKey, (oldMeetingTypes: MeetingType[] | undefined) => [
      result,
      ...(oldMeetingTypes || []),
    ]);

    return result;
  };

  const handleEdit = (slug: string, updatedMeetingType: Partial<MeetingType>) => {
    editMutation.mutate({ slug, updatedMeetingType }, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      }
    });
  };

  const handleDelete = (slug: string) => {
    deleteMutation.mutate(slug, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      }
    });
  };

  const sortedMeetingTypes = React.useMemo(() => 
    [...(meetingTypes || [])].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    ), [meetingTypes]
  );

  return render({
    data: sortedMeetingTypes,
    loading: isLoading,
    error: error ? (error as Error).message : null,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default MeetingTypeContainer;