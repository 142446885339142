import React, { useEffect, useState } from 'react';
import { useMeetingOccurrences, useCreateMeetingOccurrence, useEditMeetingOccurrence, useDeleteMeetingOccurrence } from '../api/meeting/queries';
import { MeetingOccurrence, MeetingType, TopicNoteOperation } from '../api/meeting/types';
import { useQueryClient } from 'react-query';

interface MeetingOccurrenceContainerProps {
  fetchOnMount?: boolean;
  meetingTypeId?: string;
  attendeeId?: string;
  render: (props: {
    data: MeetingOccurrence[];
    loading: boolean;
    error: string | null;
    onCreate: (newOccurrence: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'> & {
      topic_notes_operations?: TopicNoteOperation[];
    }) => Promise<MeetingOccurrence>;
    onEdit: (slug: string, updatedOccurrence: Partial<MeetingOccurrence> & {
      topic_notes_operations?: TopicNoteOperation[];
    }) => Promise<MeetingOccurrence>;
    onDelete: (slug: string) => Promise<void>;
  }) => JSX.Element;
}

const MeetingOccurrenceContainer: React.FC<MeetingOccurrenceContainerProps> = ({ 
  fetchOnMount = true, 
  meetingTypeId, 
  attendeeId, 
  render 
}) => {
  const [occurrences, setOccurrences] = useState<MeetingOccurrence[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);

  const { data, isLoading, isError, refetch } = useMeetingOccurrences();
  const createMutation = useCreateMeetingOccurrence();
  const editMutation = useEditMeetingOccurrence();
  const deleteMutation = useDeleteMeetingOccurrence();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (fetchOnMount) {
      setLoading(isLoading);
      if (data) {
        let filteredOccurrences = data;
        
        if (meetingTypeId) {
          filteredOccurrences = filteredOccurrences.filter((occurrence) => {
            if (typeof occurrence.meeting_type === 'string') {
              return occurrence.meeting_type === meetingTypeId;
            } else if (occurrence.meeting_type && typeof occurrence.meeting_type === 'object') {
              return occurrence.meeting_type.id === meetingTypeId;
            }
            return false;
          });
        }
        
        if (attendeeId) {
          filteredOccurrences = filteredOccurrences.filter((occurrence) => 
            occurrence.attendees.some(attendee => 
              typeof attendee === 'string' ? attendee === attendeeId : attendee.id === attendeeId
            )
          );
        }
        
        setOccurrences(filteredOccurrences);
        setError(null);
      }
      if (isError) {
        setError('Failed to fetch Meeting Occurrences');
      }
    }
  }, [fetchOnMount, data, isLoading, isError, meetingTypeId, attendeeId]);

  const handleCreate = async (
    newOccurrence: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'> & {
      topic_notes_operations?: TopicNoteOperation[];
    }
  ): Promise<MeetingOccurrence> => {
    try {
      const created = await createMutation.mutateAsync(newOccurrence);
      setOccurrences((prev) => [...prev, created]);
      // Invalidate both meeting occurrences and topic notes queries
      queryClient.invalidateQueries('meetingOccurrences');
      queryClient.invalidateQueries(['meeting-topic-notes', created.id]);
      await refetch();
      return created;
    } catch (error) {
      setError('Failed to create Meeting Occurrence');
      throw error;
    }
  };

  const handleEdit = async (
    slug: string, 
    updatedOccurrence: Partial<MeetingOccurrence> & {
      topic_notes_operations?: TopicNoteOperation[];
    }
  ): Promise<MeetingOccurrence> => {
    try {
      const edited = await editMutation.mutateAsync({ slug, updatedOccurrence });
      setOccurrences((prev) => prev.map((occ) => (occ.slug === slug ? edited : occ)));
      // Invalidate both meeting occurrences and topic notes queries
      queryClient.invalidateQueries('meetingOccurrences');
      queryClient.invalidateQueries(['meeting-topic-notes', edited.id]);
      await refetch();
      return edited;
    } catch (error) {
      setError('Failed to edit Meeting Occurrence');
      throw error;
    }
  };

  const handleDelete = async (slug: string): Promise<void> => {
    try {
      await deleteMutation.mutateAsync(slug);
      setOccurrences((prev) => prev.filter((occ) => occ.slug !== slug));
      queryClient.invalidateQueries('meetingOccurrences');
      await refetch();
    } catch (error) {
      setError('Failed to delete Meeting Occurrence');
      throw error;
    }
  };

  return render({
    data: occurrences,
    loading: loading || isLoading,
    error,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default MeetingOccurrenceContainer;